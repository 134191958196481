import { leadsApi } from '../pages/Leads/leadsApi';
import { boxesApi } from '../pages/Boxes/boxesApi';
import { ordersApi } from '../pages/Orders/ordersApi';
import { customersApi } from '../pages/Customer/customersApi';
import { countryBoxApi } from '../pages/CountryBox/countryBoxApi';
import { integrationsApi } from '../pages/Integrations/integrationsApi';
import { callStatusGroupApi } from '../pages/CallStatusGroups/callStatusGroupsApi';
import { permissionsApi } from '../pages/Permissions/permissionsApi';
import { usersApi } from '../pages/Users/usersApi';
import { logsApi } from '../pages/Logs/logsApi';
import { entityLogsApi } from '../pages/EntityLogs/entityLogsApi';
import { domainsApi } from '../pages/Domains/domainsApi';
import { cloudflareApi } from '../pages/Cloudflare/cloudflareApi';
import { regwayApi } from '../pages/Regway/regwayApi';
import { dashboardApi } from '../pages/Dashboard/dashboardApi';
import { teamsApi } from '../pages/Teams/teamsApi';
import { serversApi } from '../pages/Servers/serversApi';
import { offersApi } from '../pages/Offer/offersApi';
import { groupsApi } from '../pages/Group/groupsApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDeleteSessionMutation } from '../layout/Layout/AppLayout/sessionsApi';
import { kpiApi } from '../pages/Kpi/kpiApi';
import { reportsKpiApi } from '../pages/KpiReport/reportsApi';

export const useHandleLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteSession] = useDeleteSessionMutation();
  const handleLogout = () => {
    dispatch(leadsApi.util.resetApiState());
    dispatch(boxesApi.util.resetApiState());
    dispatch(ordersApi.util.resetApiState());
    dispatch(customersApi.util.resetApiState());
    dispatch(countryBoxApi.util.resetApiState());
    dispatch(integrationsApi.util.resetApiState());
    dispatch(callStatusGroupApi.util.resetApiState());
    dispatch(permissionsApi.util.resetApiState());
    dispatch(usersApi.util.resetApiState());
    dispatch(logsApi.util.resetApiState());
    dispatch(entityLogsApi.util.resetApiState());
    dispatch(domainsApi.util.resetApiState());
    dispatch(cloudflareApi.util.resetApiState());
    dispatch(regwayApi.util.resetApiState());
    dispatch(dashboardApi.util.resetApiState());
    dispatch(teamsApi.util.resetApiState());
    dispatch(serversApi.util.resetApiState());
    dispatch(offersApi.util.resetApiState());
    dispatch(groupsApi.util.resetApiState());
    dispatch(kpiApi.util.resetApiState());
    dispatch(reportsKpiApi.util.resetApiState());
    deleteSession();
    localStorage.removeItem('access_token');
    localStorage.removeItem('login_as');
    navigate('/login');
  };

  return [handleLogout];
};
