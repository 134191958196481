// store/slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const globalSlice = createSlice({
  name: 'defaultPage',
  initialState: {
    ...initialState,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSettings:(state, action)=>{
      state.crmSettings = action.payload;
    }
  },
});

export const { setUser, setLocale , setSettings} = globalSlice.actions;
export default globalSlice.reducer;
