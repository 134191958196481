import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import styles from './AppTable.module.css';
import { useNavigate } from 'react-router-dom';
import {
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
  RedoOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import ResizeTable from 'resizable-antd-table';
import ReactDragListView from 'react-drag-listview';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateIsFiltersModalOpen,
} from '../../store/sharedSlices/defaultPageSlice';
import { EditableCellGlobalForm } from '../EditableCell/EditableCellGlobalForm';
import { snakeToCamel } from '../../utils/snakeToCamel';
import { ExtendableTextTableCell } from '../ExtendableTableCell/ExtendableTableCell';
import { permissionsColumnsBuyer } from '../../utils/permissionsBuyer';
import { ThemeContext } from '../../providers/ThemeProvider';
import { hasPermissions } from '../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../utils/permissions';
import { setExcludedColumns as setExcludedOrdersColumns } from '../../pages/Orders/ordersSlice' ;
import { isLoginAsAble } from '../../utils/isLoginAsAble';
import { useHandleLogout } from '../../hooks/useHandleLogout';
import api from '../../api/api';
import { useIntl } from "react-intl";
import {camelToSnake} from "../../utils/camelToSnake";
import {DrawerCustom} from "../Drawer/Drawer";

export const AppTable = ({
  columns,
  onColumnsChange,
  data,
  filtersComponent,
  dataPrefix,
  allowChangingColumns,
  allowChangingFilters,
  showAddButton,
  createUrl,
  onCsvExport,
  onCsvImport,
  isExportDisabled,
  isImportDisabled,
  refetchAction,
  onAddNewGroupStatus,
  legendColorList,
  onRefetchColumn,
  isOpenDrawer,
  setIsOpenDrawer,
  isLoginAs,
  dataDrawer,
  ...props
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lcColumns, setLcColumns] = useState([...columns]);
  const { editingKey } = useSelector((state) => state.defaultPage);
  const { user } = useSelector((state) => state.globalSlice);
  const changedColums = localStorage.getItem(`${dataPrefix}_columns_order`);
  const dispatch = useDispatch();
  const [excludedColumns, setExcludedColumns] =  useState([]);
  const [handleLogout] = useHandleLogout();
  const { formatMessage: f } = useIntl();
  const isLoggedAs = !!localStorage.getItem('login_as')
  useEffect(() => {
    if(dataPrefix === 'users') {
      if(!user?.isAdmin)
        columns = columns.filter(column => column.dataIndex !== 'role');
    }
    if (allowChangingColumns) {
      let lc = localStorage.getItem(`${dataPrefix}_columns`);
      if (!lc) {
        localStorage.setItem(`${dataPrefix}_columns`, JSON.stringify(
          columns
            .filter((arr) => arr.hide)
            .map((arr) => arr.key )
          ));
        if(dataPrefix === 'orders') {
          dispatch(setExcludedOrdersColumns(JSON.stringify(
            columns
              .filter((arr) => arr.hide)
              .map((arr) => arr.key )
            )));
        }
      }

      lc = localStorage.getItem(`${dataPrefix}_columns`);
      const lcItems = lc ? JSON.parse(lc) : [];
      setLcColumns(
        columns.map((c) => {
          return {
            ...c,
            hide: lcItems.find((lcCol) => {
              return (
                lcCol === c.key
              );
            }),
          };
        })
      );
      if(dataPrefix === 'orders') {
        dispatch(setExcludedOrdersColumns(lc));
      }
    } else {
      setLcColumns(columns);
    }
  }, [columns]);

  if (!user) return;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loginAs = (record) => {
    const type = dataPrefix === 'buyers' ? 'buyer' : 'user';
    api
      .post(`/auth/login-as/${type}/${record.id}`, {
        type: user.type,
        device: navigator.userAgent,
      })
      .then((res) => {
        handleLogout();
        localStorage.setItem('login_as', true);
        localStorage.setItem('access_token', res.data.access_token);
        localStorage.setItem('entry_type',  res.data.user_type);
        navigate('/');
        window.location.reload();
      });

    form.setFieldsValue(record);
  };

  const mergeColumns = lcColumns.map((col, index) => ({
    ...col,
  }));

  let allowedColumnsFromUser;
  if (user.type !== 'buyer') {
    allowedColumnsFromUser = user.permissions.columns[dataPrefix];
  } else {
    allowedColumnsFromUser = permissionsColumnsBuyer();
  }

  const allowedColumns = allowedColumnsFromUser || [];
  const transformed = allowedColumns
    .map(snakeToCamel)
    .map((f) => (f.endsWith('Id') ? f.slice(0, f.length - 2) : f));
  const allowed = mergeColumns
    .filter((c) => (c.isAdminOnly ? user?.isAdmin : true))
    .filter(
      (c) =>
        c.alwaysShow ||
        transformed?.find((ac) =>
          ac.toLowerCase().includes(c.dataIndex?.toLowerCase())
        )
    );
  const finalColumns = allowedColumnsFromUser ? allowed : mergeColumns;
  const resultColumns = finalColumns
    .filter((c) => !c.hide)
    .map((col) => {
      if (col?.render === undefined) {
        col = {
          ...col,
          render: (data) => {
            return {
              children: <ExtendableTextTableCell data={data} />,
            };
          },
        };
      }
      return col;
    });

  if (isLoginAs && user.isAdmin) {
      resultColumns.push({
        title: f({ id: 'action' }),
        dataIndex: 'operation',
        align: 'center',
        render: (_, record) => {
          const loginAsAble = isLoginAsAble(user, dataPrefix, record);
          return loginAsAble.record && !isLoggedAs && (
            <span
              className = "flex justify-center" 
            >
                <Tooltip
                  title={`${f({ id: 'login_as' })} ${record.nickname}`}
                >
                  <Button
                    className="ml-2"
                    disabled={editingKey !== ''}
                    onClick={() => loginAs(record)}
                    shape="circle"
                    icon={<UserSwitchOutlined />}
                  />
                </Tooltip>
            </span>
          );
        },
      });
  }

  const changeColumnName = (key) =>  {
    if(user?.type === "buyer") {
      switch (key) {
        case "webCallStatus":
          return 'callStatus'
        case "webIsDeposit":
          return 'isDeposit';
        case "webDepositDate":
          return 'depositDate'
        case "webStatus":
          return 'status'
      }
    } else if(!user?.isAdmin && !user?.isAdminDepVisible) {
      switch (key) {
        case "generalIsDeposit":
          return 'isDeposit';
        case "generalDepositDate":
          return 'depositDate';
        case "generalStatus":
          return 'status';
      }
    }

    return key
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const columunens = [...resultColumns];
      const item = columunens.splice(fromIndex - 1, 1)[0];
      columunens.splice(toIndex - 1, 0, item);
      setLcColumns(columunens);
      localStorage.setItem(
        `${dataPrefix}_columns_order`,
        JSON.stringify(columunens.map((c) => c.key))
      );
    },
    nodeSelector: 'th',
    handleSelector: '.dragHandler',
    ignoreSelector: '.ant-table-column-title',
  };

  return (
    <div>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
      <div className={styles.tableHeader}>
        {filtersComponent}
        <div>
          <div className="flex flex-col justify-between">
            <div className="flex">
              {allowChangingColumns && (
                <Button type="primary" onClick={showModal}>
                  {f({ id: 'columns' })}
                </Button>
              )}
              {allowChangingFilters && (
                <Button
                  className={styles.addButton}
                  type="primary"
                  onClick={() => dispatch(updateIsFiltersModalOpen(true))}
                >
                  {f({ id: 'filters' })}
                </Button>
              )}
              {showAddButton && (
                <>
                  <Button
                    className={styles.addButton}
                    type="primary"
                    onClick={() => navigate(createUrl)}
                  >
                    <PlusOutlined />
                  </Button>
                  <Tooltip title={f({ id: 'refresh' })}>
                    <Button
                      className={styles.addButton}
                      type="primary"
                      onClick={() => dispatch(refetchAction)}
                    >
                      <RedoOutlined />
                    </Button>
                  </Tooltip>
                </>
              )}
              {onAddNewGroupStatus &&
                hasPermissions(user, PERMISSIONS.CALL_STATUSES_EDIT) && (
                  <>
                    <Button
                      className={styles.addButton}
                      type="primary"
                      onClick={onAddNewGroupStatus}
                    >
                      <PlusOutlined />
                    </Button>
                    <Tooltip title={f({ id: 'refresh' })}>
                      <Button
                        className={styles.addButton}
                        type="primary"
                        onClick={() => dispatch(refetchAction)}
                      >
                        <RedoOutlined />
                      </Button>
                    </Tooltip>
                  </>
                )}
              {onCsvExport && (user.isExportAvailable || user.isAdmin) && (
                <Tooltip title={f({ id: 'export_column' })}>
                  <Button
                    disabled={isExportDisabled}
                    className={styles.addButton}
                    type="primary"
                    onClick={() =>
                      onCsvExport(
                        (changedColums
                          ? resultColumns.sort((a, b) => {
                              const titleA = a?.key;
                              const titleB = b?.key;
                              const indexA = changedColums.indexOf(titleA);
                              const indexB = changedColums.indexOf(titleB);
                              if (indexA === -1) return 1;
                              if (indexB === -1) return -1;
                              return indexA - indexB;
                            })
                          : resultColumns
                        ).map((c) => c.key)
                      )
                    }
                  >
                    <ExportOutlined />
                  </Button>
                </Tooltip>
              )}
              {onCsvImport && (user?.isCanInject || user.isAdmin) && (
                <Tooltip title={f({ id: 'import_column' })}>
                  <Button
                    disabled={isImportDisabled}
                    className={styles.addButton}
                    type="primary"
                    onClick={onCsvImport}
                  >
                    <ImportOutlined />
                  </Button>
                </Tooltip>
              )}
            </div>
            {!showAddButton && !onAddNewGroupStatus && (
              <div className="flex flex-row-reverse mb-2 mt-2">
                <Tooltip title={f({ id: 'refresh' })}>
                  <Button
                    type="primary"
                    onClick={() => dispatch(refetchAction)}
                  >
                    <RedoOutlined />
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <Modal
          title={f({ id: 'show_columns' })}
          open={isModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <Col>
            {finalColumns.map((c, index) => {
              return (
                <Row key={index}>
                  <Checkbox
                    defaultChecked={!c.hide}
                    onChange={(e) => {
                      const newArr = [...finalColumns];
                      newArr[index] = {
                        ...newArr[index],
                        hide: !e.target.checked,
                      };
                      setLcColumns(newArr);
                      if (dataPrefix !== 'orders') {
                        localStorage.setItem(
                            `${dataPrefix}_columns`,
                            JSON.stringify(
                                newArr
                                    .filter((arr) => arr.hide)
                                    .map((arr) => {
                                      return arr.key
                                    })
                            )
                        );
                      } else {
                        setExcludedColumns(
                            JSON.stringify(
                                newArr
                                    .filter((arr) => arr.hide)
                                    .map((arr) => {
                                      return arr.key
                                    })
                            ));
                      }
                    }
                  }
                    >
                    {f({id: camelToSnake(changeColumnName(c.key))})}
                  </Checkbox>
                </Row>
              );
            })}
          </Col>
          {dataPrefix === 'orders' &&  (
            <div className="flex justify-center">
              <Button
                type="primary"
                onClick={() => {
                    localStorage.setItem(`orders_columns`, excludedColumns);
                    dispatch(setExcludedOrdersColumns(excludedColumns))
                    setIsModalOpen(false);
                    onRefetchColumn(excludedColumns)
                  }
                }
              >
                {f({ id: 'save' })}
              </Button>
            </div>
          )}
        </Modal>
      </div>
      <ReactDragListView.DragColumn {...dragProps}>
        <Form form={form} component={false}>
          <ResizeTable
            bordered
            showSorterTooltip={false}
            columns={
              changedColums
                ? resultColumns.sort((a, b) => {
                    const titleA = a?.key;
                    const titleB = b?.key;
                    const indexA = changedColums.indexOf(titleA);
                    const indexB = changedColums.indexOf(titleB);
                    if (indexA === -1) return 1;
                    if (indexB === -1) return -1;
                    return indexA - indexB;
                  })
                : resultColumns
            }
            dataSource={data}
            rowKey="id"
            components={{
              body: {
                cell: EditableCellGlobalForm,
              },
            }}
            {...props}
          />
        </Form>
      </ReactDragListView.DragColumn>
    </div>
  );
};
