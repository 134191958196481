import {Button, Drawer, Space} from "antd";
import React, {useEffect, useState} from "react";
import {EditUser} from "../../pages/Users/EditUser/EditUser";
import { Link, useSearchParams } from "react-router-dom";
import {handleScrollClick} from "../../utils/scrollPositionMethods";
import {EditBox} from "../../pages/Boxes/EditBox/EditBox";
import {EditOrder} from "../../pages/Orders/EditOrder/EditOrder";
import _ from "lodash";
import {EditCustomer} from "../../pages/Customer/EditCustomer/EditCustomer";
import {EditBuyer} from "../../pages/Buyers/EditBuyer/EditBuyer";
import {OfferEdit} from "../../pages/Offer/OfferEdit/OfferEdit";
import {EditGroup} from "../../pages/Group/GroupEdit/GroupEdit";
import {EditRegway} from "../../pages/Regway/EditServers/EditRegway";
import {EditServers} from "../../pages/Servers/EditServers/EditServers";
import {EditNamecheap} from "../../pages/Namecheap/EditNamecheaps/EditNamecheap";
import {EditCloudflare} from "../../pages/Cloudflare/EditCloudflare/EditCloudflare";
import {useIntl} from "react-intl";
import {EditIntegration} from "../../pages/Integrations/EditIntegration/EditIntegration";
import {EditCallStatusGroups} from "../../pages/CallStatusGroups/EditCallStatusGroups/EditCallStatusGroups";
import {EditCountryBox} from "../../pages/CountryBox/EditCloudflare/EditCountryBox";
import {EditBlacklist} from "../../pages/BlackList/EditBlacklist/EditBlacklist";
import {EditBrand} from "../../pages/Brands/EditBrand/EditBrand";
import {LeadDetails} from "../../pages/Leads/LeadDetails/LeadDetails";
import {EditTeam} from "../../pages/Teams/EditTeam/EditTeam";
import {EditPermissions} from "../../pages/Permissions/EditPermissions/EditPermissions";
import {
    EditTemplateIntegration
} from "../../pages/TemplateIntegrations/EditTemplateIntegration/EditTemplateIntegration";
import {DirectionEdit} from "../../pages/Direction/DirectionEdit/DirectionEdit";

export const DrawerCustom = ({ open, setOpen, data }) => {
    const { formatMessage: f } = useIntl();
    const [searchParams, setSearchParams] = useSearchParams();
    const [width, setWidth] = useState(1000)
    useEffect(() => {
        if(data?.id && data?.type && open) {
            let urlParse = {}
            urlParse = JSON.parse(searchParams.get('drawer')) ? JSON.parse(searchParams.get('drawer')) : null
            if (data?.type === 'call-status-groups' || data?.type === 'template-integrations') {
                setWidth(1200)
            }
            setSearchParams({
                drawer: JSON.stringify({
                    ...urlParse,
                    [data.type]: {
                        id: data.id,
                        type: data.type
                    }
                }),
            });
        }
    }, [open]);
    const onClose = () => {
        setOpen(false)
        if(searchParams?.has('drawer')) {
            const urlParse = JSON.parse(searchParams.get('drawer')) ? JSON.parse(searchParams.get('drawer')) : null
            if(urlParse?.[data.type]) delete urlParse?.[data.type]
            if(_.isEmpty(urlParse)) {
                searchParams.delete('drawer')
                setSearchParams(searchParams);
            } else {
                setSearchParams({
                    drawer:  JSON.stringify(urlParse)
                });
            }
        }
    };
    return (
        <>
            { (data?.type && data?.id) &&  <Drawer
                key={data.id}
                title={`ID: ${data.id} | ${f({id: data.type})}`}
                placement='right'
                width={width}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Link
                            onClick={() => handleScrollClick()}
                            to={`/${data?.type}/${data?.id}`}
                        >
                            <Button> {f({id: 'go_to'})} </Button>
                        </Link>
                    </Space>
                }
            >
                {data.type === 'boxes' && ( <EditBox drawerId={data.id}/>)}
                {data.type === 'orders' && ( <EditOrder drawerId={data.id}/>)}
                {data.type === 'customers' && ( <EditCustomer drawerId={data.id}/>)}
                {data.type === 'webmasters' && ( <EditBuyer drawerId={data.id}/>)}
                {data.type === 'users' && ( <EditUser drawerId={data.id}/>)}
                {data.type === 'offer' && ( <OfferEdit drawerId={data.id}/>)}
                {data.type === 'groups-offer' && ( <EditGroup drawerId={data.id}/>)}
                {data.type === 'servers' && ( <EditServers drawerId={data.id}/>)}
                {data.type === 'regway' && ( <EditRegway drawerId={data.id}/>)}
                {data.type === 'namecheap' && ( <EditNamecheap drawerId={data.id}/>)}
                {data.type === 'cloudflare' && ( <EditCloudflare drawerId={data.id}/>)}
                {data.type === 'integrations' && ( <EditIntegration drawerId={data.id}/>)}
                {data.type === 'call-status-groups' && ( <EditCallStatusGroups drawerId={data.id}/>)}
                {data.type === 'country-box' && ( <EditCountryBox drawerId={data.id}/>)}
                {data.type === 'blacklists' && ( <EditBlacklist drawerId={data.id}/>)}
                {data.type === 'brands' && ( <EditBrand drawerId={data.id}/>)}
                {data.type === 'leads' && ( <LeadDetails drawerId={data.id}/>)}
                {data.type === 'teams' && ( <EditTeam drawerId={data.id}/>)}
                {data.type === 'permissions' && ( <EditPermissions drawerId={data.id}/>)}
                {data.type === 'template-integrations' && ( <EditTemplateIntegration drawerId={data.id}/>)}
                {data.type === 'direction' && ( <DirectionEdit drawerId={data.id}/>)}
            </Drawer>
            }
        </>
    );
}