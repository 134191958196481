import { LOCALES } from './locales'
import {languages} from "../utils/languageIso";

export const messages = {
    [LOCALES.GB.locale]: {
        //  start layout
        wasNewButNowRejected:'Reject already approved lead',
        dashboard_menu: `Dashboard`,
        main_menu: `Main`,
        pinned_menu: `Favorite`,
        lead_menu: `Leads`,
        boxes_menu: `Boxes`,
        box_menu: `Boxes`,
        orders_menu: `Orders`,
        customers_menu: `Customer`,
        webs_menu: `Webmasters`,
        country_box_menu: 'Country box',
        country_box: 'Country box',
        call_statuses_menu: 'Call statuses',
        group_call_statuses_menu: 'Group call statuses',
        inject_form_menu: 'Inject form',
        reports_menu: 'Reports',
        reports_term: 'Statistics',
        advertiser: 'Advertiser',
        advertiser_term: 'Brand',
        general_reports_menu: 'General report',
        kpi_report: 'KPI report',
        report_builder_menu: 'Report builder',
        permissions_menu: 'Permissions',
        users_menu: 'Users',
        users: 'Users',
        teams_menu: 'Teams',
        management_menu: 'Management',
        administrations_menu: 'Administration',
        lead_logs_menu: 'Lead logs',
        entity_logs_menu: 'Entity logs',
        integrations_menu: 'Integrations',
        brands_menu: 'Brands',
        blacklists_menu: 'Blacklists',
        domain_purchases_menu: 'Purchase of domains',
        domains_menu: 'Domains',
        purchases_menu: 'Purchase',
        cloudflare_menu: 'Cloudflare',
        servers_menu: 'Servers',
        regway_menu: 'Regway',
        namecheap_menu: 'Namecheap',
        offers_menu: 'Offers',
        offers_term_campaign: 'Campaign',
        offers_term_commission: 'Commission',
        offers_term_product: 'Product',
        group_offers_menu: 'Group',
        profile: 'Profile',
        logout: 'Logout',
        notifications: 'Notifications',
        not_unread_notifications: 'No unread notifications',
        deposit: 'Deposit',
        is_deposit: 'Deposit',
        error_send: 'Sending error',
        reject_lead_status: 'Rejected lead status',
        inject_error_send: 'Sending error (injection)',
        inject_reject_send: 'Rejected lead status (injection)',
        status_update_failed: 'Error when updating statuses',
        change_profile: 'Change profile',
        update_user: "User updated",
        telegram_bot: 'Telegram bot',
        timezone: 'Timezone',
        save: 'Save',
        sessions: 'Sessions',
        session_using_by: 'Using by',
        unsubscribe_bot: 'Unsubscribe from the bot',
        delete_sessions: 'Delete the session? This device will log you out of the system',
        // end layout
        // start Shared
        show_filters: 'Show filters',
        check: 'Including',
        uncheck: 'Exclusively',
        country: 'Country',
        language: 'Language',
        lang: 'Language',
        delete_button:  'Delete',
        cancel:  'Cancel',
        restore: 'Restore',
        comment: 'Comment',
        executors: 'Executors',
        call_status_group: 'Call status group',
        customer: 'Customer',
        integration: 'Integration',
        permissions: 'Permissions',
        team: 'Team',
        user: 'User',
        sending: 'Sending',
        creation: 'Creation',
        create_order: 'Order creation',
        update_order: 'Order update',
        date: 'Date',
        period: 'Period',
        today: 'Today',
        yesterday: 'Yesterday',
        last_n_day: 'Last {n, number} days',
        current_month: 'Current month',
        last_month: 'Last month',
        all_the_time: 'All the time',
        inactive: 'Inactive',
        leads: 'Leads',
        orders: 'Orders',
        payment_form: 'Payment form',
        payment_type: 'Payment type',
        locked: 'locked',
        available: 'Available',
        all_details: 'All details',
        cloudflare_is_blocked:  `{ n } : Your Cloudflare account has been temporarily banned.`,
        errors_when_buying_domains:  `{ n } : Errors when buying domains: { d }`,
        inject_done:  '{ n } : The { d } lead injection is complete.',
        resend_done:  '{ n } : Sending { d } leads is complete',
        import_done:  '{ n } : Lead import is complete.',
        import_failed:  '{ n } : Error importing a lead { d }',
        change_responsible:  'Change the assigned',
        read:  'Read',
        go_to:  'Go to',
        load_more: 'Load more',
        responsible_changed: 'Assigned changed',
        change: 'Change',
        changed_dns: 'Dns has been changed',
        action: 'Action',
        actions: 'Action',
        columns: 'Columns',
        filters: 'Filters',
        refresh: 'Refresh',
        export_column: 'Export',
        import_column: 'Import',
        minutes_left:  '{ n } minutes left',
        fake: 'Fake',
        without_call_status: 'Without call status',
        show_columns: 'Show columns',
        sending_error: 'Sending error',
        pending: 'Pending',
        available_call: 'Available',
        new_call_status: 'New call status',
        undistributed: 'Undistributed',
        rejected: 'Rejected',
        approved: 'Approved',
        all: 'All',
        test: 'Test',
        resend: 'Resend',
        changeDns:'Change DNS',
        import_file: 'Import from file',
        purchase: 'Purchase',
        mass_inject: 'Mass inject',
        successful: 'Successful',
        partially_successful: 'Partially successful',
        error: 'Error',
        suspended: 'Suspended',
        in_processing: 'In processing',
        ip_is_added: 'Ip is added',
        ip_is_update: 'IP has been updated',
        incorrect_ip_address: 'Incorrect IP address',
        updated: 'Updated',
        created: 'Created',
        updated_at: 'Updated',
        created_at: 'Created',
        // end Shared

        // filters
        responsible: 'Assigned',
        responsible_buyer: 'Assigned to buyer',
        responsible_affiliate: 'Assigned to order',
        active: 'Active',
        is_active: 'Active',
        state: 'State',
        deleted: 'Deleted',
        total_record: 'Total {total} records',
        total_record_in_selected: 'Total { total } records / Selected: { selected }',

        title: 'Title',
        order: 'Order',
        duplicates: 'Duplicates',
        yes: 'Yes',
        no: 'No',
        redirection_in_duplication: 'Redirection in case of duplication',
        redirection_in_error: 'Redirection in case of an error',
        leave_to_remove_restrictions: 'Leave blank to remove restrictions',
        allow_duplicates: 'Allow duplicates',
        redirect_leads_in_duplication: 'Redirect leads to another order in case of duplication',
        redirect_leads_in_error: 'Redirect leads to another order in case of an error',
        order_updated: 'Order updated',
        order_removed_from_box:  'Order removed from the box',
        order_is_not_active:  'Order is not active',
        order_is_active:  'Order is active',
        title_order:  'Order title',
        priority: 'Priority',
        weight: 'Weight',
        daily_cap_box: 'Daily cap of the box',
        daily_cap_order_details: 'Daily order cap - is set for a specific order and includes all leads from all boxes related to this order, as well as injections for the order.',
        daily_order_cap: 'Daily order cap',
        box_added: 'Box added',
        box_updated: 'Box is updated',
        add_order_to_box: 'Add order to the box',
        tooltip_in_box_orders: 'Leave blank, or specify 0 to leave unrestricted',
        daily_cap: 'Daily cap',
        first_create_a_box: 'First create a box',
        domain: 'Domain',
        server: 'Server',
        server_ip: 'Server',
        brand_added: 'Brand added',
        brand_updated: 'Brand is updated',
        name: "Name",
        nickname: 'Nickname',
        invalid_symbol: 'The "{ s }" character is not allowed',
        password: 'Password',
        brand_of_purchase: 'Brand of purchase',
        can_inject: 'Can inject',
        can_login: 'Can login',
        can_use_api: 'Can use api',
        postback: 'Postback',
        delete_postback: 'Delete postback',
        description_postback: 'Are you sure you want to delete this postback?',
        event: 'Event',
        create_lead: 'Create a lead',
        update_lead_status: 'Update lead status',
        status: 'Status',
        link: 'Link',
        variables: 'variables: { d }',
        webmaster_added: 'Webmaster added',
        webmaster_updated: 'Webmaster is updated',
        get_documentation: 'Documentation: { d }',
        get_api_key: 'API Key: { d }',
        get_domain: 'Domain: { d }',
        details_copied: 'Details copied!',
        copy: 'Copy',
        web_details: 'Web details',
        call_status_updated: 'Call status updated',
        language_updated: 'Language updated',
        without_subcategory: 'Without subcategory',
        color: 'Color',
        category_call_status_added: 'Call status category added',
        category_call_status_updated: 'Call status category updated',
        delete_category: 'Delete category',
        sure_delete_category: 'Are you sure you want to delete this category?',
        call_status_group_deleted: 'Call status group is deleted',
        copy_group_call_statuses: 'Copy a group of Call statuses',
        not_confirmed: 'Pending',
        not_distributed: 'Not distributed',
        confirmed: 'Approved',
        declined: 'Rejected',
        updated_lead_by_status: 'Leads are being updated by status, please wait.',
        status_group_cloned: 'Call status group cloned',
        group_call_status_added: 'Call status group added',
        group_call_status_updated: 'Call status group updated',
        login: 'Login',
        login_as: 'Login as', 
        fields_is_incorrect: 'The fields are incorrect',
        cloudflare_added: 'Cloudflare added',
        cloudflare_updated: 'Cloudflare updated',
        country_box_added: 'Country box added',
        country_box_updated: 'Country box updated',
        customer_added: 'Customer added',
        customer_updated: 'Customer updated',
        country_one: 'Country',
        brand: 'Brand',
        sell_brand: 'Selling brand',
        buy_brand: 'Buying brand',
        leads_dashboard: 'Leads',
        all_leads: 'All leads',
        invalid: 'Invalid',
        box: 'Box',
        offer: 'Offer',
        offer_id: 'Offer',
        unique_leads: 'Unique leads',
        price: 'Price',
        sell_price: 'Sell price',
        buy_price: 'Buy price',
        documentation: 'Documentation',
        will_be_corrected: 'Will be corrected',
        unavailable: 'Unavailable',
        buy: 'Buy',
        buyer: 'Buyer',
        buyers: 'Buyers',
        main_info: 'Main info',
        method_purchase: 'Purchase method',
        buy_is_similar: 'If the domain is not available, buy a similar one',
        checkin: 'Check',
        details: 'Details',
        response_detail: 'Details',
        table: 'Table',
        data: 'Data',
        entity_id: 'Entity ID',
        group_added: 'Group added',
        group_updated: 'Group updated',
        type: 'Type',
        description: 'Description',
        lead_sent: 'Lead sent',
        last_name: 'Last name',
        phone: 'Phone',
        platform: 'Platform',
        send_without_autologin: 'Send without autologin',
        enhanced_stealth: 'Enhanced stealth',
        autologin_method: 'Autologin method',
        send: 'Send',
        integration_added: 'Integration added',
        integration_updated: 'Integration updated',
        sending_lead: 'Sending leads',
        check_status: 'Check status',
        executor: 'Executor',
        external_id: 'External ID',
        general_status: 'General status',
        call_status: 'Call status',
        call_status_history: 'Call status history',
        web_call_status_history: 'Web call status history',
        general_deposit: 'General deposit',
        web_status: 'WEB status',
        current_status: 'WEB status',
        fake_web_status: 'Fake web status',
        web_call_status: 'Web call status',
        current_call_status: 'Web call status',
        fake_web_call_status: 'Fake web call status',
        web_is_deposit: 'Web deposit',
        web_deposit: 'Web deposit',
        current_is_deposit: 'Web deposit',
        fake_web_deposit: 'Fake web deposit',
        deposit_available: 'Deposit available',
        no_deposit: 'No deposit',
        status_lock: 'Status lock',
        general_status_lock: 'General status lock',
        call_status_lock: 'Call status lock',
        next: 'Next',
        it_will_be_stamped: "It'll be stamped",
        inject: 'Inject',
        is_injected: 'Inject',
        locked_status: 'Locked status',
        unprocessed_leads: 'Unprocessed leads',
        confirmed_filters: 'Confirmed',
        status_changed: 'Status changed',
        change_lead_state: 'Change lead state',
        change_approved_description: 'The following leads will not be updated, as the following is disabled in the order manual deposit confirmation',
        change_approved_wrong: 'The following leads will not be updated because they do not have a deposit',
        change_lead_status: 'Change lead status',
        change_lead_language: 'Change lead language',
        change_status_system: 'The following leads will not be updated because they are in the system status',
        file_contains_errors: 'The file contains validation errors. Please correct and upload again',
        import_leads: 'Import leads',
        file_template: 'File template',
        select_file_validate: 'Please select a file',
        click_upload_file: 'Click or drag a file to upload',
        start: 'Start',
        select_start_time: 'Please select a start time',
        minimal_interval: 'Minimal interval (min)',
        maximum_interval: 'Maximum interval (min)',
        simulate_autologin: 'Simulate autologin',
        start_inject_background: 'Lead injection started in the background',
        inject_leads: 'Inject leads',
        start_resend_background: 'Lead resend started in the background',
        resend_leads: 'Resend leads',
        resend_error_description: 'Only leads in status "{ n }" and with an existing box can be resent. The following leads will not be resent:',
        total_deposit_date: 'Total deposit date',
        deposit_date: 'Deposit date',
        previous_call_status: 'Previous Call status',
        autologin: 'Autologin',
        is_auto_login: 'Autologin',
        autologin_date: 'Autologin date',
        auto_login_at: 'Autologin date',
        autologin_ip: 'Autologin Ip',
        fraud_ip: 'Fraud Ip',
        frod_ip: 'Fraud Ip',
        injected_by: 'Injected by',
        sent: 'Sent',
        sent_at: 'Sent',
        lead_updated: 'lead updated',
        mark_test: 'Mark as a test',
        status_n_lead_updated: 'Status { n } leads updated',
        change_status: 'Change status',
        change_language:'Change language',
        approved_deposit: 'Approve deposits',
        update_status: 'Update statuses',
        updated_status: 'Status updated',
        update_call_status: 'Update call status',
        will_suspended: 'Will be suspended',
        webmaster: 'Webmaster',
        manager: ' Manager',
        email_validate: 'Please enter your email',
        password_validate: 'Please enter your password',
        error_login: 'Incorrect email or password',
        sign_in: 'Sign in',
        lead_data: 'Lead data',
        lead: 'Lead',
        namecheap_added: 'Namecheap added',
        namecheap_updated: 'Namecheap updated',
        balance: 'Balance',
        balance_updated: 'Balance updated - { n }',
        refresh_balance: 'Refresh balance',
        no_permission: 'You do not have permission to view this page',
        offer_added: 'Offer added',
        offer_updated: 'Offer updated',
        group: 'Group',
        group_offer: 'Group',
        groups: 'Group',
        author: 'Author',
        preview: 'Preview',
        order_added: 'Order added',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        start_date: 'Start date',
        started_at: 'Start date',
        date_of_execution: 'Date of execution',
        ended_at: 'Date of execution',
        select_date_description: 'The date is inclusive. The call centre time zone is used',
        lead_price: 'Lead price',
        cr_planned: 'CR target',
        cr_percent: 'CR target',
        cap: 'Cap',
        affiliate:'Affiliate',
        affiliate_term_partner:'Partner',
        affiliate_term_influencer:'Influencer',
        affiliate_term_publisher:'Publisher',
        leads_paid: 'Leads paid',
        manual_deposit_confirmation: 'Manual deposit confirmation',
        please_specify_time: 'Please specify a time',
        permissions_added: 'Permissions added',
        permissions_updated: 'Permissions updated',
        customers: 'Customers',
        call_statuses: 'Call statuses',
        call_status_groups: 'Call status groups',
        country_boxes: 'Country boxes',
        logs: 'Логи',
        only_your: 'Only your',
        just_your_own_team: 'Just your own team',
        own_and_child_teams: "Own and child teams",
        entities: 'Entities',
        entity: 'Entity',
        allowed: 'Allowed',
        visibility: 'Visibility',
        nothing: 'Nothing',
        reading: 'Reading',
        edit: 'Edit',
        regway_added: 'Regway added',
        regway_updated: 'Regway updated',
        server_added: 'Server added',
        server_updated: 'Server updated',
        errors: 'Errors',
        lead_log: 'Lead log',
        queue_deleted: 'Queue deleted',
        delete_queue: 'Delete queue',
        queue_on_pause: 'Queue on pause',
        queue_restored: 'The queue has been restored',
        queue_updated: 'The queue has been updated',
        pause: 'Pause',
        progress: 'Progress',
        total_number: 'Total number',
        end_date: 'End date',
        log: 'Log',
        team_added: 'Team added',
        team_updated: 'Team updated',
        edit_button: 'Edit',
        inherited_from: 'Inherited from',
        add_user_to_team: 'Add user to the team',
        user_added: 'User added',
        user_updated: 'User updated',
        role: 'Role',
        rights_in_team: 'Rights in a team',
        can_resend_lead: 'Can resend',
        can_set_fake_data: 'Can set fake lead data',
        can_pay: 'Can pay',
        access_to_report_builder: 'Access to the report builder',
        has_access_to_export: 'Has access to export',
        can_change_integration_in_order: 'Can change integration in the order',
        is_admin_dep_visible: 'Can see admin deposits',
        access_to_change_statuses: 'Has access to change statuses',
        can_edit_lead: 'Can edit lead',
        please_contact: 'No access, contact',
        admin: 'Admin',
        day: 'Day',
        month: 'Month',
        group_offers: 'Group offers',
        revenues: 'Revenues',
        percentages: 'Percentages',
        total: "Total",
        grouping: 'Grouping',
        add: 'Add',
        from_sending: 'From (sending)',
        to_sending: 'To (sending)',
        general_cap: 'General cap',
        leads_sent: 'Leads sent',
        valid_leads: 'Valid leads',
        leads_valid: 'Valid leads',
        call_status_updates: 'Status update call statuses',
        last_successful_call_status_updates: 'Last successful call status updates',
        call_status_update_status: 'Last successful call status updates',
        call_status_updated_at: 'Last successful call status updates',
        total_balance_page: "Total balance on page",
        highlight_available: 'Highlight available',
        all_previews: "All previews",
        filter_by_date: 'Filter by date',
        duplicate: 'Duplicate',
        tooltip_duplicate: 'Create permissions based on these',
        back_to_account: 'Return to the main account',
        change_executor: 'Change executor',
        change_webmaster: 'Change webmaster',
        share: 'Share',
        shared: 'Shared',
        shared_scope_id_only: 'ID Only',
        shared_scope_full: 'Full access',
        scope: 'Scope',
        general_is_deposit: 'General deposit',
        utm_campaign: 'utm_campaign',
        utm_medium: 'utm_medium',
        utm_content: 'utm_content',
        utm_source: 'utm_source',
        utm_term: 'utm_term',
        url: 'Url',
        uuid: 'uuid',
        id: 'Id',
        email: 'Email',
        ip: 'Ip',
        user_agent: 'User agent',
        sub1: 'sub1',
        sub2: 'sub2',
        sub3: 'sub3',
        sub4: 'sub4',
        sub5: 'sub5',
        sub6: 'sub6',
        sub7: 'sub7',
        sub8: 'sub8',
        caps_menu: 'Caps',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: "Fri",
        saturday: 'Sat',
        sunday: 'Sun',
        boxes: 'Boxes',
        buyers: 'Webmasters',
        teams: 'Teams',
        all_caps: 'All caps on this page',
        daily_cap_buyer: 'Daily cap of the webmaster',
        real_deposit_date: 'Total deposit date',
        web_deposit_date: 'Web deposit date',
        countries: 'Countries',
        ftd: 'FTD',
        cr: 'CR',
        api_key: 'API key',
        shared_scope_full_leads: 'Full + leads',
        is_redirect_duplicates: 'Redirection in case of duplication',
        is_redirect_error: 'Redirection in case of an error',
        cloudflare: 'Cloudflare',
        namecheap: 'Namecheap',
        regway: 'Regway',
        api_email: 'API email',
        id_account: 'Account id',
        keitaro_key: 'Keitaro key',
        r_id: 'Regway id',
        r_key: 'Regway key',
        r_user_id: 'Regway user id',
        r_contact_id: 'Regway contact id',
        api_user: 'API user',
        user_name: 'Username',
        age: 'Age',
        dont_use_console_log: 'The string contains console.warn or console.error.',
        download: 'Download',
        file_will_deleted_in_download: 'File will be deleted after downloading',
        file_has_been_deleted: 'File has been deleted',
        file_generated_will_available_download: 'The file is generated and will be available for download in 15 minutes',
        add_2FA: 'Add 2FA',
        remove_2FA: 'Remove 2FA',
        added_2FA: '2FA added',
        removed_2FA: '2FA removed',
        code_is_incorrect: 'The code is incorrect',
        open_qr_code: 'Open QR code',
        please_add_2FA: 'Please add 2FA',
        install_the_app: 'Install the app',
        scan_the_QR_code: 'Scan the QR code',
        confirmation: 'Confirmation',
        enter_code_from_app_to_delete_2FA: 'Enter the code from the app to delete 2FA',
        otp_text_1: 'The most common applications for this are Google Authenticator, Authy or Microsoft Authenticator.',
        otp_text_2: 'When adding a new account for OTP authentication, the app will prompt the user to scan a QR code. This allows you to configure the generation of one-time codes for this account.',
        otp_text_3: 'Once the QR code is scanned, the app automatically sets up the generation of 6-digit one-time codes. The user will need to enter this code.',
        accesses: 'Accesses',
        set_fake_lead_information: 'Set fake lead information',
        payment_allowed: 'Payment allowed',
        fake_report_builder: 'Fake report builder',
        change_integration: 'Change integration',
        edit_lead: 'Edit lead',
        change_lead_box: 'Change lead box',
        change_box: 'Change box',
        leads_updated: 'Leads updated',
        change_leads_box: 'Change leads box',
        these_leads_do_not_have_the_unassigned_status: "These leads do not have the 'Unassigned' status, so the box cannot be changed for them",
        traffic_type: 'Traffic type',
        live: "Live",
        inject_file: 'Inject from file',
        inject_form: 'inject from the form',
        inject_box: 'Inject to box',
        command_cannot_be_deleted: 'The command cannot be deleted while there are users or child commands',
        approved_tooltip: 'Lead accepted and confirmed by the client and will be paid.',
        pending_tooltip: 'Lead accepted by the client and is in the process of being handled.',
        unsigned_tooltip: 'Lead was not sent due to restrictions. More information in the "Details" field.',
        rejected_tooltip: 'Lead was not accepted by the client due to restrictions and will not be paid. More information in the "Details" field.',
        error_tooltip: 'An error occurred while sending the lead to the client. More information in the "Details" field.',
        test_tooltip: 'Lead marked as a test, this lead will not be counted in our system.',
        notDistributed_tooltip: 'The client marked the lead with a new call status that is not yet distributed in the system.',
        hold_tooltip: 'Lead was not sent due to restrictions.',

        live_tooltip: 'Lead entered the system independently',
        inject_tooltip: 'Lead was injected into the system',
        inject_file_tooltip: 'Lead was injected via a file',
        inject_form_tooltip: 'Lead was injected via a form',
        inject_box_tooltip: 'Lead was injected to box',
        resend_tooltip: 'Lead was resent',
        empty: 'Empty',
        integration_template: 'Integration template',
        integration_data: 'Integration data',
        company_name: 'Company name',
        please_enter_company_name: 'Please enter company name',
        please_enter_title: 'Please enter title',
        upload_new_logo: 'Upload new logo',
        upload_new_favicon: 'Upload new favicon',
        open: 'Open',
        generation: 'Generation',
        settings: 'Settings',
        delay_call_status: 'Delay call status',
        funnels: 'Funnels',
        send_on_inject: 'Send on inject?',
        direction: 'Direction',
        direction_group: 'Direction group',
        is_answer: 'Answer',
        not_answered: 'Not answered',
        answered: 'Answered',
        method_determining_country: 'Method determining country',
        received_country: 'Received Country',
        use_hold_status: 'Use hold status',
        hold: 'On hold',
        at_work: 'At work',
        on_pause: 'On pause',
        delayed_call_status: 'Delayed call status',
        new_unsigned_lead: 'New unsigned lead',
    },
    [LOCALES.RU.locale]: {
        // start layout
        wasNewButNowRejected:'Отклонение уже принятого лида',
        dashboard_menu: `Главная`,
        main_menu: `Основное`,
        pinned_menu: `Выбранные`,
        lead_menu: 'Лиды',
        box_menu: `Боксы`,
        boxes_menu: `Боксы`,
        orders_menu: `Заказы`,
        customers_menu: `Заказчик`,
        webs_menu: `Веби`,
        country_box_menu: 'Country box',
        country_box: 'Country box',
        call_statuses_menu: 'Call статусы',
        group_call_statuses_menu: 'Группы call статусов',
        groups: 'Группы',
        inject_form_menu: 'Инжект форма',
        reports_menu: 'Отчеты',
        reports_term: 'Статистика',
        advertiser: 'Рекламодатель',
        advertiser_term: 'Бренд',
        general_reports_menu: 'Общий отчет',
        kpi_report: 'KPI отчет',
        report_builder_menu: 'Конструктор отчетов',
        management_menu: 'Менеджмент',
        permissions_menu: 'Права',
        users_menu: 'Пользователи',
        users: 'Пользователи',
        teams_menu: 'Команды',
        administrations_menu: 'Администрирование',
        lead_logs_menu: 'Логи лидов',
        entity_logs_menu: 'Логи сущностей',
        integrations_menu: 'Интеграции',
        brands_menu: 'Бренди',
        blacklists_menu: 'Черный список',
        domain_purchases_menu: 'Покупка доменов',
        domains_menu: 'Домены',
        purchases_menu: 'Покупка',
        cloudflare_menu: 'Cloudflare',
        servers_menu: 'Серверы',
        regway_menu: 'Regway',
        namecheap_menu: 'Namecheap',
        offers_menu: 'Офери',
        offers_term_campaign: 'Кампания',
        offers_term_commission: 'Комиссия',
        offers_term_product: 'Продукт',
        group_offers_menu: 'Группы',
        profile: 'Профиль',
        logout: 'Выход',
        notifications: 'Нотификации',
        not_unread_notifications: 'Нет непрочитанных нотификаций',
        deposit: 'Депозит',
        is_deposit: 'Депозит',
        error_send: 'Ошибка отправки',
        reject_lead_status: 'Отклоненный статус лида',
        inject_error_send: 'Ошибка отправки (инжект)',
        inject_reject_send: 'Отклоненный статус лида (инжект)',
        status_update_failed: 'Ошибка при обновлении статусов',
        change_profile: 'Изменить профиль',
        update_user: "Пользователь обновлен",
        telegram_bot: 'Телеграм бот',
        timezone: 'Часовой пояс',
        save: 'Сохранить',
        sessions: 'Сессии',
        session_using_by: 'Используется пользователем',
        unsubscribe_bot: 'Отписаться от бота',
        delete_sessions: 'Удалить сессию? На этом пристое будет сделан выход из системы',
        // end layout
        // start Shared
        show_filters: 'Показать фильтры',
        check: 'Включая',
        uncheck: 'Исключительно',
        country: 'Страны',
        language: 'Язык',
        lang: 'Язык',
        delete_button: 'Удалить',
        cancel:  'Отменить',
        restore: 'Восстановить',
        comment: 'Комментарий',
        executors: 'Исполнители',
        call_status_group: 'Call status група',
        customer: 'Заказчик',
        integration: 'Интеграция',
        permissions: 'Права',
        team: 'Команда',
        user: 'Пользователь',
        sending: 'Отправки',
        creation: 'Создание',
        create_order: 'Создание заказа',
        update_order: 'Обновление заказа',
        date: 'Дата',
        period: 'Период',
        today: 'Сегодня',
        yesterday: 'Вчера',
        last_n_day: 'Последние {n, number} дней',
        current_month: 'Текущий месяц',
        last_month: 'Прошлый месяц',
        all_the_time: 'Все время',
        inactive: 'Неактивное',
        leads: 'Лиды',
        orders: 'Заказы',
        payment_form: 'Форма оплаты',
        payment_type: 'Тип оплаты',
        locked: 'Заблокированы',
        available: 'Свободные',
        all_details: 'Все детали',
        cloudflare_is_blocked:  `{ n } : Cloudflare аккаунт попал во временный бан.`,
        errors_when_buying_domains:  `{ n } : Ошибки при покупке доменов: { d }`,
        inject_done:  '{ n } : Инджект { d } лидов завершен.',
        resend_done:  '{ n } : Переотправка { d } лидов завершена',
        import_done:  '{ n } : Импорт лидов завершен.',
        import_failed:  '{ n } : Ошибка при импорте лида { d }',
        change_responsible:  'Сменить ответственного',
        read:  'Прочитано',
        go_to:  'Перейти',
        load_more: 'Загрузить еще',
        responsible_changed: 'Ответственный изменен',
        change: 'Изменить',
        changed_dns: 'DNS изменен',
        action: 'Действие',
        actions: 'Действие',
        columns: 'Колонки',
        filters: 'Фильтры',
        refresh: 'Обновить',
        export_column: 'Экспорт',
        import_column: 'Импорт',
        minutes_left:  'Осталось { n } хвилин',
        fake: 'Фейк',
        without_call_status: 'без call статуса',
        show_columns: 'Показать колонки',
        sending_error: 'Ошибка отправки',
        pending: 'В обработке',
        new_call_status: 'Новый call статус',
        undistributed: 'Нераспределенный',
        rejected: 'Отклоненный',
        approved: 'Подтвержденный',
        all: 'Все',
        test: 'Тест',
        resend: 'Переотправка',
        changeDns:'Изменить DNS',
        available_call: 'Доступные',
        import_file: 'Импорт из файла',
        purchase: 'Покупка',
        mass_inject: 'Массовый инжект',
        successful: 'Успешный',
        partially_successful: 'Частично успешный',
        error: 'Ошибка',
        suspended: 'Остановлен',
        in_processing: 'В обработке',
        ip_is_added: 'IP добавлен',
        ip_is_update: 'IP обновлён',
        incorrect_ip_address: 'Некорректный IP-адрес',
        updated: 'Обновлено',
        created: 'Создано',
        updated_at: 'Обновлено',
        created_at: 'Создано',
        // end Shared

        // filters
        responsible: 'Ответственный',
        responsible_buyer: 'Ответственный по баеру',
        responsible_affiliate: 'Ответственный по заказу',
        active: 'Активный',
        is_active: 'Активный',
        state: 'Стан',
        deleted: 'Удаленные',
        total_record: 'Всего {total} записей',
        total_record_in_selected: 'Всего { total } записей / Выбрано: { selected }',

        title: 'Название',
        order: 'Заказ',
        duplicates: 'Дубликаты',
        yes: 'Да',
        no: 'Нет',
        redirection_in_duplication: 'Перенаправление в случае дубликата',
        redirection_in_error: 'Перенаправление в случае ошибки',
        leave_to_remove_restrictions: 'Оставьте пустым, чтобы убрать ограничения',
        allow_duplicates: 'Разрешить дубликаты',
        redirect_leads_in_duplication: 'Перенаправлять лиды на другой заказ в случае дубля',
        redirect_leads_in_error: 'Перенаправлять лиды на другой заказ в случае ошибки',
        order_updated: 'Заказ обновлен',
        order_removed_from_box:  'Заказ удален из бокса',
        order_is_not_active:  'Заказ неактивен',
        order_is_active:  'Заказ активен',
        title_order:  'Название заказа',
        priority: 'Приоритет',
        weight: 'Вес',
        daily_cap_box: 'Дневной кап бокса',
        daily_cap_order_details: 'Дневной кап заказа - устанавливается для конкретного заказа и включает все лиды из всех боксов, касающихся этого заказа, а также инжекты на заказ.',
        daily_order_cap: 'Дневной кап заказа',
        box_added: 'Бокс добавлен',
        box_updated: 'Бокс обновлен',
        add_order_to_box: 'Добавить заказ в бокс',
        tooltip_in_box_orders: 'Оставьте пустым, или укажите 0, чтобы оставить без ограничения',
        daily_cap: 'Дневной кап',
        first_create_a_box: 'Сначала создайте бокс',
        domain: 'Домен',
        server: 'Сервер',
        server_ip: 'Сервер',
        brand_added: 'Бренд добавлен',
        brand_updated: 'Бренд обновлен',
        name: "Имя",
        nickname: 'Ник',
        invalid_symbol: 'Знак "{s}" недопустим',
        password: 'Пароль',
        brand_of_purchase: 'Бренд покупки',
        can_inject: 'Может инджектить',
        can_login: 'Может логиниться',
        can_use_api: 'Может пользоваться api',
        postback: 'Постбеки',
        delete_postback: 'Удалить постбек',
        description_postback: 'Вы уверены, что хотите удалить этот постбек?',
        event: 'Событие',
        create_lead: 'Создание лида',
        update_lead_status: 'Обновление статуса лида',
        status: 'Статус',
        link: 'Ссылка',
        variables: 'Переменные: { d }',
        webmaster_added: 'Вебмастера добавлено',
        webmaster_updated: 'Вебмастера обновлен',
        get_documentation: 'Документация: { d }',
        get_api_key: 'API Key: { d }',
        get_domain: 'Домен: { d }',
        details_copied: 'Детали скопированы!',
        copy: 'Копировать',
        web_details: 'Детали веба',
        call_status_updated: 'Call статус обновлен',
        language_updated: 'Язык обновлен',
        without_subcategory: 'Без подкатегории',
        color: 'Цвет',
        category_call_status_added: 'Категория call статусов добавлена',
        category_call_status_updated: 'Категория call статусов обновлен',
        delete_category: 'Удалить категорию',
        sure_delete_category: 'Вы уверены, что хотите удалить эту категорию?',
        call_status_group_deleted: 'Группа call status удалена',
        copy_group_call_statuses: 'Скопировать группу Call статусов',
        not_confirmed: 'Неподтвержденные',
        not_distributed: 'Нераспределенные',
        confirmed: 'Подтвержденные',
        declined: 'Отклоненные',
        updated_lead_by_status: 'Обновляются лиды по статусу, подождите.',
        status_group_cloned: 'Call статус группа клонирована',
        group_call_status_added: 'Група call статусов добавлена',
        group_call_status_updated: 'Група call статусов обновлена',
        login: 'Логин',
        login_as: 'Зайти под', 
        fields_is_incorrect: 'Не верно указанные поля',
        cloudflare_added: 'Cloudflare добавлен',
        cloudflare_updated: 'Cloudflare обновлен',
        country_box_added: 'Country box добавлен',
        country_box_updated: 'Country box обновлен',
        customer_added: 'Заказчик добавлен',
        customer_updated: 'Заказчик обновлен',
        country_one: 'Страна',
        brand: 'Бренд',
        sell_brand: 'Бренд продажи',
        buy_brand: 'Бренд покупки',
        leads_dashboard: 'Лиды',
        all_leads: 'Все лиды',
        invalid: 'Невалидные',
        box: 'Бокс',
        offer: 'Офер',
        offer_id: 'Офер',
        unique_leads: 'Уникальные лиды',
        price: 'Цена',
        sell_price: 'Цена продажи',
        buy_price: 'Цена покупки',
        documentation: 'Документация',
        will_be_corrected: 'Будут исправлены',
        unavailable: 'Недоступные',
        buy: 'Купить',
        buyer: 'Байер',
        buyers: 'Байера',
        main_info: 'Основные данные',
        method_purchase: 'Метод покупки',
        buy_is_similar: 'Если домен не доступен - купить похожий',
        checkin: 'Проверить',
        details: 'Детали',
        response_detail: 'Детали',
        table: 'Таблица',
        data: 'Данные',
        entity_id: 'ID сущности',
        group_added: 'Группа добавлена',
        group_updated: 'Группа обновлена',
        type: 'Тип',
        description: 'Описание',
        lead_sent: 'Лид отправлен',
        last_name: 'Фамилия',
        phone: 'Телефон',
        platform: 'Платформа',
        send_without_autologin: 'Отправить без автологина',
        enhanced_stealth: 'Улучшеная скрытность',
        autologin_method: 'Метод автологина',
        send: 'Отправить',
        integration_added: 'Интеграция добавлена',
        integration_updated: 'Интеграция обновлена',
        sending_lead: 'Отправка лида',
        check_status: 'Проверка статуса',
        executor: 'Исполнитель',
        external_id: 'Внешний ID',
        general_status: 'Общий статус',
        call_status: 'Call статус',
        call_status_history: 'История call статусов',
        web_call_status_history: 'Web история call статусов',
        general_deposit: 'Общий депозит',
        web_status: 'Web статус',
        current_status: 'Web статус',
        fake_web_status: 'Фейк web статус',
        web_call_status: 'Web call статус',
        current_call_status: 'Web call статус',
        fake_web_call_status: 'Фейк web call статус',
        web_deposit: 'Web депозит',
        current_is_deposit: 'Web депозит',
        fake_web_deposit: 'Фейк web депозит',
        deposit_available: 'Депозит доступен',
        no_deposit: 'Депозит отсутствует',
        status_lock: 'Блокирование статуса',
        general_status_lock: 'Блокирование генерального статуса',
        call_status_lock: 'Блокировка call статуса',
        next: 'Следующий',
        it_will_be_stamped: 'Будет проставлено',
        inject: 'Инжект',
        is_injected: 'Инжект',
        locked_status: 'Заблокированный статус',
        unprocessed_leads: 'Не обработанные лиды',
        confirmed_filters: 'Подтвержденные',
        status_changed: 'Статусы изменены',
        change_lead_state: 'Изменить состояние лидов',
        change_approved_description: 'Следующие лиды не будут обновлены, поскольку в заказе отключено ручное подтверждение депозита',
        change_approved_wrong: 'Следующие лиды не будут обновлены, поскольку в них отсутствует депозит',
        change_lead_status: 'Изменить статус лидов',
        change_lead_language: 'Изменить язык лидов',
        change_status_system: 'Следующие лиды не будут обновлены, поскольку находятся в системном статусе',
        file_contains_errors: 'Файл содержит валидационные ошибки.Пожалуйста, исправьте и загрузите заново',
        import_leads: 'Импорт лидов',
        file_template: 'Шаблон файла',
        select_file_validate: 'Пожалуйста, выберите файл',
        click_upload_file: 'Кликните или перетащите файл для загрузки',
        start: 'Начало',
        select_start_time: 'Пожалуйста, выберите время начала',
        minimal_interval: 'Минимальный интервал (мин)',
        maximum_interval: 'Максимальный интервал (мин)',
        simulate_autologin: 'Симулировать автологин',
        start_inject_background: 'Инджект лидов начался в фоновом режиме',
        inject_leads: 'Заинжектить лиды',
        start_resend_background: 'Переотправка лидов началась в фоновом режиме',
        resend_leads: 'Переотправить ліди',
        resend_error_description: 'Переотправить можно только лиды в статусе "{ n }" и с имеющимся боксом. Следующие лиды не будут переотправлены:',
        total_deposit_date: 'Общая дата депозита',
        deposit_date: 'Дата депозита',
        previous_call_status: 'Предварительный Call статус',
        autologin: 'Автологин',
        is_auto_login: 'Автологин',
        autologin_date: 'Дата автологина',
        auto_login_at: 'Дата автологина',
        autologin_ip: 'Автологин Ip',
        fraud_ip: 'Фрод Ip',
        frod_ip: 'Фрод Ip',
        injected_by: 'Заинджевктив',
        sent: 'Отправлено',
        sent_at: 'Отправлено',
        lead_updated: 'Лид обновлен',
        mark_test: 'Отметить как тест',
        status_n_lead_updated: 'Статус {n} лидов обновлен',
        change_status: 'Изменить статус',
        change_language:'Изменить язык',
        approved_deposit: 'Подтвердить депозиты',
        update_status: 'Обновить статусы',
        updated_status: 'Статус обновлен',
        update_call_status: 'Обновить call статус',
        will_suspended: 'Будет приостановлено',
        webmaster: 'Вебмастер',
        manager: 'Менеджер',
        email_validate: 'Пожалуйста, введите свой email',
        password_validate: 'Пожалуйста, введите свой пароль',
        error_login: 'Неверный Email или пароль',
        sign_in: 'Войти',
        lead_data: 'Данные лида',
        lead: 'Лид',
        namecheap_added: 'Namecheap добавлен',
        namecheap_updated: 'Namecheap обновлен',
        balance: 'Баланс',
        balance_updated: 'Баланс обновлен - { n }',
        refresh_balance: 'Обновить баланс',
        no_permission: 'У вас нет прав на просмотр этой страницы',
        offer_added: 'Офер добавлен',
        offer_updated: 'Офер обновлен',
        group: 'Група',
        group_offer: 'Група',
        author: 'Автор',
        preview: 'Предпросмотр',
        order_added: 'Заказ добавлен',
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Середа',
        thursday: 'Четверг',
        friday: "Пятница",
        saturday: "Суббота",
        sunday: "Воскресенье",
        start_date: 'Дата начала',
        started_at: 'Дата начала',
        date_of_execution: 'Дата выполнения',
        ended_at: 'Дата выполнения',
        select_date_description: 'Дата указывается включительно. Используется таймзона коллцентра',
        lead_price: 'Цена лида',
        cr_planned: 'CR плановый',
        cr_percent: 'CR плановый',
        cap: 'Кап',
        affiliate:'Аффилейт',
        affiliate_term_partner:'Партнер',
        affiliate_term_influencer:'Инфлюенсер',
        affiliate_term_publisher:'Издатель',
        leads_paid: 'Лидов оплачено',
        manual_deposit_confirmation: 'Ручное подтверждение депозита',
        please_specify_time: 'Пожалуйста, укажите время',
        permissions_added: 'Права добавлены',
        permissions_updated: 'Права обновлены',
        customers: 'Заказчики',
        call_statuses: 'Колл статусы',
        call_status_groups: 'Группы колл статусов',
        country_boxes: 'Country боксы',
        logs: 'Логи',
        only_your: 'Только свои',
        just_your_own_team: 'Только своя команда',
        own_and_child_teams: "Своя и дочерние команды",
        entities: 'Сущности',
        entity: 'Сущность',
        allowed: 'Разрешено',
        visibility: 'Видимость',
        nothing: 'Ничего',
        reading: 'Чтение',
        edit: 'Редактирование',
        regway_added: 'Regway добавлен',
        regway_updated: 'Regway обновлен',
        server_added: 'Сервер добавлен',
        server_updated: 'Сервер обновлен',
        errors: 'Ошибки',
        lead_log: 'Журнал лидов',
        queue_deleted: 'Очередь удалена',
        delete_queue: 'Удалить очередь',
        queue_on_pause: 'Очередь на паузе',
        queue_restored: 'Очередь восстановлена',
        queue_updated: 'Очередь обновлена',
        pause: 'Пауза',
        progress: 'Прогресс',
        total_number: 'Общее количество',
        end_date: 'Дата окончания',
        log: 'Журнал',
        team_added: 'Команда добавлена',
        team_updated: 'Команда обновлена',
        edit_button: 'Редактировать',
        inherited_from: 'Наследуется от',
        add_user_to_team: 'Добавить пользователя в команду',
        user_added: 'Пользователь добавлен',
        user_updated: 'Пользователь обновлен',
        role: 'Роль',
        rights_in_team: 'Права в команде',
        can_resend_lead: 'Может перезапускать',
        can_set_fake_data: 'Может задавать фейковые данные лида',
        can_pay: 'Может оплачивать',
        access_to_report_builder: 'Имеет доступ к конструктору отчетов',
        has_access_to_export: 'Имеет доступ к выгрузке',
        can_change_integration_in_order: 'Может менять интеграцию в заказе',
        is_admin_dep_visible: 'Может видеть админ депозиты',
        access_to_change_statuses: 'Имеет доступ к изменению статусов',
        can_edit_lead: 'Может редактировать лид',
        please_contact: 'Нет доступа, обратитесь к',
        admin: 'Админ',
        day: 'День',
        month: 'Месяц',
        group_offers: 'Группа офферов',
        revenues: 'Доход',
        percentages: 'Проценты',
        total: "Всего",
        grouping: 'Группировка',
        add: 'Добавить',
        from_sending: 'От (отправка)',
        to_sending: 'До (отправка)',
        general_cap: 'Общий кап',
        leads_sent: 'Лидов отправлено',
        valid_leads: 'Лидов валидных',
        leads_valid: 'Лидов валидных',
        call_status_updates: 'Status update call statuses',
        last_successful_call_status_updates: 'Последнее успешное обновление call статусов',
        call_status_update_status: 'Последнее успешное обновление call статусов',
        call_status_updated_at: 'Последнее успешное обновление call статусов',
        total_balance_page: "Всего баланс на странице",
        highlight_available: 'Подсветить доступные',
        all_previews: "Все превью",
        filter_by_date: 'Фильтровать по дате',
        duplicate: 'Дублировать',
        tooltip_duplicate: 'Создать права на основе данных',
        back_to_account: 'Вернуться к основному акаунту',
        change_executor: 'Изменить исполнителя',
        change_webmaster: 'Сменить вебмастера',
        share: 'Выдать доступ',
        shared: 'Доступ выдан',
        shared_scope_id_only: 'Только айди',
        shared_scope_full: 'Полный',
        scope: 'Область видимости',
        general_is_deposit: 'Общий депозит',
        utm_campaign: 'utm_campaign',
        utm_medium: 'utm_medium',
        utm_content: 'utm_content',
        utm_source: 'utm_source',
        utm_term: 'utm_term',
        url: 'Url',
        uuid: 'uuid',
        id: 'Id',
        email: 'Email',
        ip: 'Ip',
        user_agent: 'User agent',
        sub1: 'sub1',
        sub2: 'sub2',
        sub3: 'sub3',
        sub4: 'sub4',
        sub5: 'sub5',
        sub6: 'sub6',
        sub7: 'sub7',
        sub8: 'sub8',
        caps_menu: 'Капы',
        monday: 'Пн',
        tuesday: 'Вт',
        wednesday: 'Ср',
        thursday: 'Чт',
        friday: "Пт",
        saturday: 'Сб',
        sunday: 'Вс',
        boxes: 'Боксы',
        buyers: 'Вебы',
        teams: 'Команды',
        all_caps: 'Все капы на странице', 
        daily_cap_buyer: 'Дневной кап веба',
        real_deposit_date: 'Общая дата депозита',
        web_deposit_date: 'Web дата депозита',
        countries: 'Страны',
        ftd: 'FTD',
        cr: 'CR',
        api_key: 'API key',
        shared_scope_full_leads: 'Полный + лиды',
        is_redirect_duplicates: 'Перенаправление в случае дубликата',
        is_redirect_error: 'Перенаправление в случае ошибки',
        cloudflare: 'Cloudflare',
        namecheap: 'Namecheap',
        regway: 'Regway',
        api_email: 'API email',
        id_account: 'Account id',
        keitaro_key: 'Keitaro key',
        r_id: 'Regway id',
        r_key: 'Regway key',
        r_user_id: 'Regway user id',
        r_contact_id: 'Regway contact id',
        api_user: 'API user',
        user_name: 'Username',
        age: 'Age',
        dont_use_console_log: 'Строка содержит console.warn или console.error.',
        download: 'Загрузить',
        file_will_deleted_in_download: 'Файл будет удален после загрузки',
        file_has_been_deleted: 'Файл был удален',
        file_generated_will_available_download: 'Файл формируется после чего будет доступен для загрузки 15 минут',
        add_2FA: 'Добавить 2FA',
        remove_2FA: 'Удалить 2FA',
        added_2FA: '2FA добавлено',
        removed_2FA: '2FA удалена',
        code_is_incorrect: 'Неверно заполненный код',
        open_qr_code: 'Открыть QR-код',
        please_add_2FA: 'Пожалуйста, добавьте 2FA',
        install_the_app: 'Установи приложение',
        scan_the_QR_code: 'Сканируй QR-код',
        confirmation: 'Подтверждение',
        enter_code_from_app_to_delete_2FA: 'Введите код из приложения, чтобы удалить 2FA',
        otp_text_1: 'Самыми распространенными приложениями для этого являются Google Authenticator, Authy или Microsoft Authenticator.',
        otp_text_2: 'При добавлении новой учетной записи для OTP аутентификации, приложение предложит пользователю сканировать QR-код. Это позволяет настроить генерацию одноразовых кодов для данной учетной записи.',
        otp_text_3: 'После сканирования QR-кода, приложение автоматически настраивает генерацию 6-цифровых одноразовых кодов. Пользователю нужно будет ввести этот код.',
        accesses: 'Имеет доступ',
        set_fake_lead_information: 'Устанавливать фейковую информацию лида',
        payment_allowed: 'Оплачивать',
        fake_report_builder: 'Конструктор отчетов',
        change_integration: 'Изменять интеграцию',
        edit_lead: 'Редактирование лида',
        change_lead_box: 'Изменять бокс лида',
        change_box: 'Изменить бокс',
        leads_updated: 'Лиды обновлены',
        change_leads_box: 'Изменить бокс лидов',
        these_leads_do_not_have_the_unassigned_status: "Эти Лиды не имеют статуса 'Нераспределенный', поэтому бокс в них нельзя изменить",
        traffic_type: 'Тип трафика',
        live: "Живой",
        inject_file: 'Инжект из файла',
        inject_form: 'Инжект с формы',
        inject_box: 'Инжект на бокс',
        command_cannot_be_deleted: 'Невозможно удалить команду, пока есть пользователи или дочерние команды.',
        approved_tooltip: 'Лид принят и подтвержден заказчиком и будет оплачен.',
        pending_tooltip: 'Лид принят заказчиком и находится в процессе обработки.',
        unsigned_tooltip: 'Лид не был отправлен из-за ограничений. Более подробная информация в поле "Детали".',
        rejected_tooltip: 'Лид не был принят заказчиком из-за ограничений и не будет оплачен. Более подробная информация в поле "Детали".',
        error_tooltip: 'Во время отправки лида заказчику произошла ошибка. Более подробная информация в поле "Детали".',
        test_tooltip: 'Лид помечен как тестовый, этот лид не будет учитываться в нашей системе.',
        notDistributed_tooltip: 'Заказчик пометил лид новым call-статусом, который еще не распределен в системе.',
        hold_tooltip: 'Лид не был отправлен из-за ограничений.',


        live_tooltip: 'Лид самостоятельно вошел в систему',
        inject_tooltip: 'Лид был инжектирован в систему',
        inject_file_tooltip: 'Лид был инжектирован через файл',
        inject_form_tooltip: 'Лид был инжектирован через форму',
        inject_box_tooltip: 'Лид был инжектирован через бокс',
        resend_tooltip: 'Лид был повторно отправлен',
        empty: 'Пусто',
        integration_template: 'Шаблон интеграций',
        integration_data: 'Данные интеграции',
        company_name: 'Название компании',
        please_enter_company_name: 'Пожалуйста, введите название компании',
        please_enter_title: 'Пожалуйста, введите заголовок',
        upload_new_logo: 'Загрузить новый логотип',
        upload_new_favicon: 'Загрузить новый фавикон',
        open: 'Открыть',
        generation: 'Генерация',
        settings: 'Настройки',
        delay_call_status: 'Задержать call status',
        funnels: 'Воронка',
        send_on_inject: 'Отправить при инжекте?',
        direction: 'Направление',
        direction_group: 'Направление группы',
        is_answer: 'Ответ',
        not_answered: 'Не ответили',
        answered: 'Ответили',
        method_determining_country: 'Метод определения страны',
        received_country: 'Полученная страна',
        use_hold_status: 'Использовать hold статус',
        hold: 'На удержании',
        at_work: 'В работе',
        on_pause: 'На паузе',
        delayed_call_status: 'Приостановленный call status',
        new_unsigned_lead: 'Новый нераспределенный лид',
    },
    [LOCALES.UA.locale]: {
        // start layout
        wasNewButNowRejected:'Відхилення вже прийнятого ліда',
        dashboard_menu: `Головна`,
        main_menu: `Основне`,
        pinned_menu: `Обране`,
        lead_menu: 'Ліди',
        box_menu: `Бокси`,
        boxes_menu: `Бокси`,
        orders_menu: `Замовлення`,
        customers_menu: `Замовник`,
        webs_menu: `Веби`,
        country_box_menu: 'Country box',
        country_box: 'Country box',
        call_statuses_menu: 'Call статуси',
        group_call_statuses_menu: 'Групи call статусів',
        groups: 'Групи',
        inject_form_menu: 'Інжект форма',
        reports_menu: 'Звіти',
        reports_term: 'Статистика',
        advertiser: 'Рекламодавець',
        advertiser_term: 'Бренд',
        general_reports_menu: 'Загальний звіт',
        kpi_report: 'KPI звіт',
        report_builder_menu: 'Конструктор звітів',
        management_menu: 'Менеджмент',
        permissions_menu: 'Права',
        users_menu: 'Користувачі',
        users: 'Користувачі',
        teams_menu: 'Команди',
        administrations_menu: 'Адміністрування',
        lead_logs_menu: 'Логи лідів',
        entity_logs_menu: 'Логи сутностей',
        integrations_menu: 'Інтеграції',
        brands_menu: 'Бренди',
        blacklists_menu: 'Чорний список',
        domain_purchases_menu: 'Покупка доменів',
        domains_menu: 'Домени',
        purchases_menu: 'Покупка',
        cloudflare_menu: 'Cloudflare',
        servers_menu: 'Сервери',
        regway_menu: 'Regway',
        namecheap_menu: 'Namecheap',
        offers_menu: 'Офери',
        offers_term_campaign: 'Кампанія',
        offers_term_commission: 'Комісія',
        offers_term_product: 'Продукт',
        group_offers_menu: 'Групи',
        profile: 'Профіль',
        logout: 'Вихід',
        notifications: 'Нотифікації',
        not_unread_notifications: 'Немає непрочитаних нотифікацій',
        deposit: 'Депозит',
        is_deposit: 'Депозит',
        error_send: 'Помилка відправки',
        reject_lead_status: 'Відхилений статус ліда',
        inject_error_send: 'Помилка відправки (інжект)',
        inject_reject_send: 'Відхилений статус ліда (інжект)',
        status_update_failed: 'Помилка при оновленні статусів',
        change_profile: 'Змінити профіль',
        update_user: "Користувача оновлено",
        telegram_bot: 'Телеграм бот',
        timezone: 'Часовий пояс',
        save: 'Зберегти',
        sessions: 'Сесії',
        session_using_by: 'Використовується користувачем',
        unsubscribe_bot: 'Відписатися від бота',
        delete_sessions: 'Видалити сесію? На цьому пристої буде зроблено вихід із системи',
        // end layout
        // start shared
        show_filters: 'Показати фільтри',
        check: 'Включно',
        uncheck: 'Виключно',
        country: 'Країни',
        language: 'Мова',
        lang: 'Мова',
        delete_button: 'Видалити',
        cancel:  'Відмінити',
        restore: 'Відновити',
        comment: 'Коментар',
        executors: 'Виконавці',
        call_status_group: 'Call status група',
        customer: 'Замовник',
        integration: 'Інтеграція',
        permissions: 'Права',
        team: 'Команда',
        user: 'Користувач',
        sending: 'Відправки',
        creation: 'Створення',
        create_order: 'Створення замовлення',
        update_order: 'Оновлення замовлення',
        date: 'Дата',
        period: 'Період',
        today: 'Сьогодні',
        yesterday: 'Вчора',
        last_n_day: 'Останні {n, number} днів',
        current_month: 'Поточний місяць',
        last_month: 'Минулий місяць',
        all_the_time: 'Весь час',
        inactive: 'Неактивне',
        leads: 'Ліди',
        orders: 'Замовлення',
        payment_form: 'Форма оплати',
        payment_type: 'Тип оплати',
        locked: 'Заблоковані',
        available: 'Вільні',
        all_details: 'Усі деталі',
        cloudflare_is_blocked:  `{ n } : Cloudflare аккаунт потрапив в тимчасовий бан.`,
        errors_when_buying_domains:  `{ n } : Помилки при покупкі доменів: { d }`,
        inject_done:  '{ n }: Інджект { d } лідів завершено.',
        resend_done:  '{ n }: Перевідправка { d } лідів завершена.',
        import_done:  '{ n }: Імпорт лідів завершено.',
        import_failed:  '{ n }: Помилка при іморті ліда { d }',
        change_responsible:  'Змінити відповідального',
        share: 'Дати доступ',
        shared: 'Доступ видано',
        shared_scope_id_only: 'Тільки айді',
        shared_scope_full: 'Повний',
        read:  'Прочитано',
        go_to:  'Перейти',
        load_more: 'Завантажити ще',
        responsible_changed: 'Відповідального змінено',
        change: 'Змінити',
        changed_dns: 'DNS змінено',
        action: 'Дія',
        actions: 'Дія',
        columns: 'Колонки',
        filters: 'Фільтри',
        refresh: 'Оновити',
        export_column: 'Експорт',
        import_column: 'Імпорт',
        available_call: 'Доступні',
        minutes_left:  'Залишилося { n } хвилин',
        fake: 'Фейк',
        without_call_status: 'без call статусу',
        show_columns: 'Показати колoнки',
        sending_error: 'Помилка відправки',
        pending: 'В обробці',
        new_call_status: 'Новий call статус',
        undistributed: 'Нерозподілений',
        rejected: 'Відхилений',
        approved: 'Підтверджений',
        all: 'Всі',
        test: 'Тест',
        resend: 'Перевідправка',
        changeDns:'Змінити DNS',
        import_file: 'Імпорт з файла',
        purchase: 'Покупка',
        mass_inject: 'Масовий інжект',
        successful: 'Успішний',
        partially_successful: 'Частично успішний',
        error: 'Помилка',
        suspended: 'Зупинений',
        in_processing: 'В обробці',
        ip_is_added: 'IP додано',
        ip_is_update: 'IP оновлено',
        incorrect_ip_address: 'Некоректний IP-адрес',
        updated: 'Оновлено',
        created: 'Створено',
        updated_at: 'Оновлено',
        created_at: 'Створено',
        // end shared

        // columns
        responsible: 'Відповідальний',
        responsible_buyer: 'Відповідальний по баеру',
        responsible_affiliate: 'Відповідальний по замовленню',
        active: 'Активне',
        is_active: 'Активне',
        state: 'Стан',
        deleted: 'Видалені',
        total_record: 'Всього {total} записів',
        total_record_in_selected: 'Всього { total } записів / Вибрано: { selected }',

        title: 'Назва',
        order: 'Замовлення',
        duplicates: 'Дублі',
        yes: 'Так',
        no: 'Ні',
        redirection_in_duplication: 'Перенаправлення в разі дубліката',
        redirection_in_error: 'Перенаправлення в разі помилки',
        leave_to_remove_restrictions: 'Залиште пустим, щоб прибрати обмеження',
        allow_duplicates: 'Дозволити дублікати',
        redirect_leads_in_duplication: 'Перенаправляти ліди на інше замовлення в разі дубля',
        redirect_leads_in_error: 'Перенаправляти ліди на інше замовлення в разі помилки',
        order_updated: 'Замовлення оновлено',
        order_removed_from_box:  'Замовлення видалено з бокса',
        order_is_not_active:  'Замовлення не активне',
        order_is_active:  'Замовлення активне',
        title_order:  'Назва замовлення',
        priority: 'Пріорітет',
        weight: 'Вага',
        daily_cap_box: 'Денний кап бокса',
        daily_cap_order_details: 'Денний кап замовлення - встановлюється для конкретного замовлення і включає всі ліди із всіх боксів, що стосуються цього замовлення, а також інжекти на замовлення.',
        daily_order_cap: 'Денний кап замовлення',
        box_added: 'Бокс додано',
        box_updated: 'Бокс оновлено',
        add_order_to_box: 'Додати замовлення до бокса',
        tooltip_in_box_orders: 'Залиште пустим, або вкажіть 0, щоб залишити без обмеження',
        daily_cap: 'Денний кап',
        first_create_a_box: 'Спочатку створіть бокс',
        domain: 'Домен',
        server: 'Сервер',
        server_ip: 'Сервер',
        brand_added: 'Бренд додано',
        brand_updated: 'Бренд оновлено',
        name: "Ім'я",
        nickname: 'Нік',
        invalid_symbol: 'Знак "{ s }" недопустимий',
        password: 'Пароль',
        brand_of_purchase: 'Бренд покупки',
        can_inject: 'Може інджектити',
        can_login: 'Може логінитися',
        can_use_api: 'Може користуватися api',
        postback: 'Постбеки',
        delete_postback: 'Видалити постбек',
        description_postback: 'Ви впевнені, що хочете видалити цей постбек?',
        event: 'Подія',
        create_lead: 'Створення ліда',
        update_lead_status: 'Оновлення статуса ліда',
        status: 'Статус',
        link: 'Посилання',
        variables: 'Змінні: { d }',
        webmaster_added: 'Вебмайстера додано',
        webmaster_updated: 'Вебмайстер оновлений',
        get_documentation: 'Документація: { d }',
        get_api_key: 'API Key: { d }',
        get_domain: 'Домен: { d }',
        details_copied: 'Деталі скопійовано!',
        copy: 'Копіювати',
        web_details: 'Деталі веба',
        call_status_updated: 'Call статус оновлено',
        language_updated: 'Мову оновлено',
        without_subcategory: 'Без підкатегорії',
        color: 'Колір',
        category_call_status_added: 'Категорію call статусів додано',
        category_call_status_updated: 'Категорію call статусів оновлений',
        delete_category: 'Видалити категорію',
        sure_delete_category: 'Ви впевнені, що хочете видалити цю категорію?',
        call_status_group_deleted: 'Групу call status видалено',
        copy_group_call_statuses: 'Скопіювати групу call статусів',
        not_confirmed: 'Непідтверджені',
        not_distributed: 'Нерозподілені',
        confirmed: 'Підтверджені',
        declined: 'Відхилені',
        updated_lead_by_status: 'Оновлюються ліди за статусом, зачекайте.',
        status_group_cloned: 'Call статус групу клоновано',
        group_call_status_added: 'Групу call статусів додано',
        group_call_status_updated: 'Групу call статусів оновлений',
        login: 'Логін',
        login_as: 'Зайти як', 
        fields_is_incorrect: 'Невірно вказані поля',
        cloudflare_added: 'Cloudflare додано',
        cloudflare_updated: 'Cloudflare оновлено',
        country_box_added: 'Country box додано',
        country_box_updated: 'Country box оновлено',
        customer_added: 'Замовника додано',
        customer_updated: 'Замовника оновлено',
        country_one: 'Країна',
        brand: 'Бренд',
        sell_brand: 'Бренд продажу',
        buy_brand: 'Бренд покупки',
        leads_dashboard: 'Ліди',
        all_leads: 'Всі ліди',
        invalid: 'Невалідні',
        box: 'Бокс',
        offer: 'Офер',
        offer_id: 'Офер',
        unique_leads: 'Унікальні ліди',
        price: 'Ціна',
        sell_price: 'Ціна продажу',
        buy_price: 'Ціна покупки',
        documentation: 'Документація',
        will_be_corrected: 'Будуть виправлені',
        unavailable: 'Недоступні',
        buy: 'Купити',
        buyer: 'Баєр',
        buyers: 'Баєра',
        main_info: 'Основні відомості',
        method_purchase: 'Метод покупки',
        buy_is_similar: 'Якщо домен не доступний - купити схожий',
        checkin: 'Перевірити',
        details: 'Деталі',
        response_detail: 'Деталі',
        table: 'Таблиця',
        data: 'Дані',
        entity_id: 'ID сутності',
        group_added: 'Групу додано',
        group_updated: 'Групу оновлено',
        type: 'Тип',
        description: 'Опис',
        lead_sent: 'Лід відправлено',
        last_name: 'Прізвище',
        phone: 'Телефон',
        platform: 'Платформа',
        send_without_autologin: 'Відправити без автологіна',
        enhanced_stealth: 'Покращена скритність',
        autologin_method: 'Метод автологіна',
        send: 'Відправити',
        integration_added: 'Інтеграцію додано',
        integration_updated: 'Інтеграцію оновлено',
        sending_lead: 'Відправка ліда',
        check_status: 'Перевірка статуса',
        executor: 'Виконавець',
        external_id: 'Зовнішній ID',
        general_status: 'Загальний статус',
        call_status: 'Call статус',
        call_status_history: 'Історія call статусів',
        web_call_status_history: 'Web історія call статусів',
        general_deposit: 'Загальний депозит',
        web_status: 'WEB статус',
        current_status: 'WEB статус',
        fake_web_status: 'Фейк web статус',
        web_call_status: 'Web call статус',
        current_call_status: 'Web call статус',
        web_is_deposit: 'Web депозит',
        web_deposit: 'Web депозит',
        current_is_deposit: 'Web депозит',
        fake_web_deposit: 'Фейк web депозит',
        deposit_available: 'Депозит наявний',
        no_deposit: 'Депозит відсутній',
        status_lock: 'Блокування статусу',
        general_status_lock: 'Блокування генерального статусу',
        call_status_lock: 'Блокування call статусу',
        next: 'Наступний',
        it_will_be_stamped: 'Буде проставлено',
        inject: 'Інжект',
        is_injected: 'Інжект',
        locked_status: 'Заблокований статус',
        unprocessed_leads: 'Не оброблені ліди',
        confirmed_filters: 'Підтверджені',
        status_changed: 'Статуси змінено',
        change_lead_state: 'Змінити стан лідів',
        change_approved_description: 'Наступні ліди не будуть оновлені, оскільки в замовлені відключене ручне підтвердження депозиту',
        change_approved_wrong: 'Наступні ліди не будуть оновлені, оскільки в них відсутній депозит',
        change_lead_status: 'Змінити статус лідів',
        change_lead_language: 'Змінити мову лідів',
        change_status_system: 'Наступні ліди не будуть оновлені, оскільки знаходяться в системному статусі',
        file_contains_errors: 'Файл містить валідаційні помилки. Будь ласка, виправте і завантажте заново',
        import_leads: 'Імпорт лідів',
        file_template: 'Шаблон файлу',
        select_file_validate: 'Будь ласка, виберіть файл',
        click_upload_file: 'Клікніть або перетягніть файл для завантаження',
        start: 'Початок',
        select_start_time: 'Будь ласка, виберіть час початку',
        minimal_interval: 'Мінімальний інтервал (хв)',
        maximum_interval: 'Максимальний інтервал (хв)',
        simulate_autologin: 'Симулювати автологін',
        start_inject_background: 'Інджект лідів почався в фоновому режимі',
        start_resend_background: 'Перевідправка лідів почався в фоновому режимі',
        inject_leads: 'Заінджектити ліди',
        resend_leads: 'Перевідправити ліди',
        resend_error_description: 'Перевідправити можна тільки ліди в статусі "{ n }" та з наявним боксом. Наступні ліди не будуть перевідправлені:',
        total_deposit_date: 'Загальна дата депозита',
        deposit_date: 'Дата депозита',
        previous_call_status: 'Попередній Call статус',
        autologin: 'Автологін',
        is_auto_login: 'Автологін',
        autologin_date: 'Дата автологіна',
        auto_login_at: 'Дата автологіна',
        autologin_ip: 'Автологін Ip',
        fraud_ip: 'Фрод Ip',
        frod_ip: 'Фрод Ip',
        injected_by: 'Заінджевктив',
        sent: 'Відправлено',
        sent_at: 'Відправлено',
        lead_updated: 'Лід оновленно',
        mark_test: 'Помітити як тест',
        status_n_lead_updated: 'Статус {n} лідів оновлено',
        change_status: 'Змінити статус',
        change_language:'Змінити мову',
        approved_deposit: 'Підтвердити депозити',
        update_status: 'Оновити статуси',
        updated_status: 'Статус оновлено',
        update_call_status: 'Оновити call статус',
        will_suspended: ' Буде призупинено',
        webmaster: 'Вебмайстер',
        manager: 'Менеджер',
        email_validate: 'Будь ласка, введіть свій email',
        password_validate: 'Будь ласка, введіть свій пароль',
        error_login: 'Невірний Email або пароль',
        sign_in: 'Увійти',
        lead_data: 'Дані ліда',
        lead: 'Лід',
        namecheap_added: 'Namecheap додано',
        namecheap_updated: 'Namecheap оновлено',
        balance: 'Баланс',
        balance_updated: 'Баланс оновлено - { n }',
        refresh_balance: 'Обновить баланс',
        no_permission: 'У вас немає прав для перегляду цієї сторінки',
        offer_added: 'Офер додано',
        offer_updated: 'Офер оновлено',
        group: 'Група',
        group_offer: 'Група',
        author: 'Автор',
        preview: 'Передперегляд',
        order_added: 'Замовлення додано',
        monday: 'Понеділок',
        tuesday: 'Вівторок',
        wednesday: 'Середа',
        thursday: 'Четвер',
        friday: "П'ятниця",
        saturday: "Субота",
        sunday: "Неділя",
        start_date: 'Дата початку',
        started_at: 'Дата початку',
        date_of_execution: 'Дата виконання',
        ended_at: 'Дата виконання',
        select_date_description: 'Дата вказується включно. Використовується таймзона коллцентра',
        lead_price: 'Ціна ліда',
        cr_planned: 'CR плановий',
        cr_percent: 'CR плановий',
        cap: 'Кап',
        affiliate:'Аффілейт',
        affiliate_term_partner:'Партнер',
        affiliate_term_influencer:'Інфлюенсер',
        affiliate_term_publisher:'Видавець',
        leads_paid: 'Лідів оплачено',
        manual_deposit_confirmation: 'Ручне підтвердження депозиту',
        please_specify_time: 'Будь ласка, вкажіть час',
        permissions_added: 'Права додано',
        permissions_updated: 'Права оновлено',
        customers: 'Замовники',
        call_statuses: 'Колл статуси',
        call_status_groups: 'Групи колл статусів',
        country_boxes: 'Country бокси',
        logs: 'Логи',
        only_your: 'Тільки свої',
        just_your_own_team: 'Тільки своя команда',
        own_and_child_teams: "Своя і дочірні команди",
        entities: 'Сутності',
        entity: 'сутність',
        allowed: 'Дозволено',
        visibility: 'Видимість',
        nothing: 'Нічого',
        reading: 'Читання',
        edit: 'Редагування',
        regway_added: 'Regway додано',
        regway_updated: 'Regway оновлено',
        server_added: 'Сервер додано',
        server_updated: 'Сервер оновлено',
        errors: 'Помилки',
        lead_log: 'Журнал лідів',
        queue_deleted: 'Чергу видалено',
        delete_queue: 'Видалити чергу',
        queue_on_pause: 'Черга на паузі',
        queue_restored: 'Черга відновленна',
        queue_updated: 'Черга оновлена',
        pause: 'Пауза',
        progress: 'Прогрес',
        total_number: 'Загальна кількість',
        end_date: 'Дата кінця',
        log: 'Журнал',
        team_added: 'Команду додано',
        team_updated: 'Команду оновлено',
        edit_button: 'Редагувати',
        inherited_from: 'Наслідується від',
        add_user_to_team: 'Додати корисувача до команди',
        user_added: 'Користувача додано',
        user_updated: 'Користувача оновлено',
        role: 'Роль',
        rights_in_team: 'Права в команді',
        can_resend_lead: 'Може перевідпавляти',
        can_set_fake_data: 'Може задавати фейкові дані ліду',
        can_pay: 'Може оплачувати',
        access_to_report_builder: 'Має доступ до конструктора звітів',
        has_access_to_export: 'Має доступ вигрузки',
        can_change_integration_in_order: 'Може змінювати інтеграцію в замовленні',
        is_admin_dep_visible: 'Може бачити адмін депозити',
        access_to_change_statuses: 'Має доступ до зміни статусів',
        can_edit_lead: 'Може редагувати лід',
        please_contact: 'Немає доступу, зверніться до',
        admin: 'Адмін',
        day: 'День',
        month: 'Місяць',
        group_offers: 'Група офферів',
        revenues: 'Дохід',
        percentages: 'Проценти',
        total: "Всього",
        grouping: 'Групування',
        add: 'Додати',
        from_sending: 'Від (відправка)',
        to_sending: 'До (відправка)',
        general_cap: 'Загальний кап',
        leads_sent: 'Лідів відправлено',
        valid_leads: 'Лідів валідних',
        leads_valid: 'Лідів валідних',
        call_status_updates: 'Статус оновлення call статусів',
        last_successful_call_status_updates: 'Останнє успішне оновлення call статусів',
        call_status_update_status: 'Останнє успішне оновлення call статусів',
        call_status_updated_at: 'Останнє успішне оновлення call статусів',
        total_balance_page: "Всього баланс на сторінці",
        highlight_available: 'Підсвітити доступні',
        all_previews: "Всі прев'ю",
        filter_by_date: 'Фільтрувати по даті',
        duplicate: 'Дублювати',
        tooltip_duplicate: 'Створити права на основі цих',
        back_to_account: 'Повернутися до основного акаунту',
        change_executor: 'Змінити виконавця',
        change_webmaster: 'Змінити вебмайстра',
        scope: 'Область видимості',
        general_is_deposit: 'Загальний депозит',
        utm_campaign: 'utm_campaign',
        utm_medium: 'utm_medium',
        utm_content: 'utm_content',
        utm_source: 'utm_source',
        utm_term: 'utm_term',
        url: 'Url',
        uuid: 'uuid',
        id: 'Id',
        email: 'Email',
        ip: 'Ip',
        user_agent: 'User agent',
        boxes: 'Бокси',
        webmasters: 'Веби',
        users: 'Користувачі',
        groups_offer: 'Группа оферів',
        servers: 'Сервери',
        regway: 'Regway',
        namecheap: 'Namecheap',
        cloudflare: 'Cloudflare',
        integrations: 'Інтеграції',
        blacklists: 'Чорний список',
        brands: 'Бренди',
        teams: 'Команди',
        sub1: 'sub1',
        sub2: 'sub2',
        sub3: 'sub3',
        sub4: 'sub4',
        sub5: 'sub5',
        sub6: 'sub6',
        sub7: 'sub7',
        sub8: 'sub8',
        caps_menu: 'Капи',
        monday: 'Пн',
        tuesday: 'Вт',
        wednesday: 'Ср',
        thursday: 'Чт',
        friday: "Пт",
        saturday: 'Сб',
        sunday: 'Нд',
        boxes: 'Бокси',
        buyers: 'Веби',
        teams: 'Команди',
        all_caps: 'Всі капи на сторінці', 
        daily_cap_buyer: 'Денний кап веба',
        real_deposit_date: 'Загальна дата депозита',
        web_deposit_date: 'Web дата депозита',
        countries: 'Країни',
        ftd: 'FTD',
        cr: 'CR',
        api_key: 'API key',
        shared_scope_full_leads: 'Повний + ліди',
        is_redirect_duplicates: 'Перенаправлення в разі дубліката',
        is_redirect_error: 'Перенаправлення в разі помилки',
        cloudflare: 'Cloudflare',
        namecheap: 'Namecheap',
        regway: 'Regway',
        api_email: 'API email',
        id_account: 'Account id',
        keitaro_key: 'Keitaro key',
        r_id: 'Regway id',
        r_key: 'Regway key',
        r_user_id: 'Regway user id',
        r_contact_id: 'Regway contact id',
        api_user: 'API user',
        user_name: 'Username',
        age: 'Age',
        dont_use_console_log: 'Рядок містить console.warn або console.error',
        download: 'Завантажити',
        file_will_deleted_in_download: 'Файл буде видалений після завантаження',
        file_has_been_deleted: 'Файл був видалений',
        file_generated_will_available_download: 'Файл формується після чого буде доступний для завантаження 15 хвилин',
        add_2FA: 'Додати 2FA',
        remove_2FA: 'Видалити 2FA',
        added_2FA: '2FA додано',
        removed_2FA: '2FA видалено',
        code_is_incorrect: 'Невірно заповнено код',
        open_qr_code: 'Відкрити QR-код',
        please_add_2FA: 'Будь ласка, додайте 2FA',
        install_the_app: 'Встанови додаток',
        scan_the_QR_code: 'Скануй QR-код',
        confirmation: 'Підтвердження',
        enter_code_from_app_to_delete_2FA: 'Введіть код із додатка, щоб видалити 2FA',
        otp_text_1: 'Найпоширенішими додатками для цього є Google Authenticator, Authy або Microsoft Authenticator.',
        otp_text_2: 'Під час додавання нового облікового запису для OTP аутентифікації, додаток запропонує користувачеві сканувати QR-код. Це дозволяє налаштувати генерацію одноразових кодів для даного облікового запису.',
        otp_text_3: 'Після сканування QR-коду, додаток автоматично налаштовує генерацію 6-цифрових одноразових кодів. Користувачу потрібно буде ввести цей код.',
        accesses: 'Має доступ',
        set_fake_lead_information: 'Ставити фейкові дані ліда',
        payment_allowed: 'Оплачувати',
        fake_report_builder: 'Конструктор звітів',
        change_integration: 'Змінювати інтеграцію',
        edit_lead: 'Редагування ліда',
        change_lead_box: 'Змінювати бокс ліда',
        change_box: 'Змінити бокс',
        leads_updated: 'Ліди оновлено',
        change_leads_box: 'Змінити бокс лідів',
        these_leads_do_not_have_the_unassigned_status: "Ці ліди не мають статусу 'Нерозподілений', тому бокс в них не можливо змінити",
        traffic_type: 'Тип трафіку',
        live: "Живий",
        inject_file: 'Інжект із файлу',
        inject_form: 'Інжект із форми',
        inject_box: 'Інжект на бокс',
        command_cannot_be_deleted: 'Неможливо видалити команду, поки є користувачі або дочірні команди.',
        approved_tooltip: 'Лід прийнятий та підтверджений замовником та буде оплачений.',
        pending_tooltip: 'Лід прийнятий замовником і знаходиться в процесі обробки.',
        unsigned_tooltip: 'Лід не був відправлений через обмеження. Більше інформації в полі "Деталі"',
        rejected_tooltip: 'Лід не був прийнятий замовником через обмеження. та не буде оплаченим Більше інформації в полі "Деталі"',
        error_tooltip: 'Під час відправки ліда замовнику сталася помилка. Більше інформації в полі "Деталі"',
        test_tooltip: 'Лід позначили як тест, цей лід не буде враховуватися в нашій системі',
        notDistributed_tooltip: 'Замовник позначив лід новим call-статусом який іще не розподілений у системі',
        hold_tooltip: 'Лід не був відправлений через обмеження.',

        live_tooltip: 'Лід самостійно зайшов в систему',
        inject_tooltip: 'Лід заінжектили в системі',
        inject_file_tooltip: 'Лід заінжектили через файл',
        inject_form_tooltip: 'Лід заінжектили через форму',
        inject_box_tooltip: 'Лід заінжектили через бокс',
        resend_tooltip: 'Лід було перевідправлено',
        empty: 'Пусто',
        company_name: 'Назва компанії',
        please_enter_company_name: 'Будь ласка, введіть назву компанії',
        please_enter_title: 'Будь ласка, введіть заголовок',
        upload_new_logo: 'Завантажити новий логотип',
        upload_new_favicon: 'Завантажити новий фавікон',
        open: 'Відкрити',
        generation: 'Генерація',
        settings: 'Налаштування',

        integration_template: 'Шаблон інтеграцій',
        integration_data: 'Дані інтеграції',
        delay_call_status: 'Затримати call status',
        funnels: 'Воронка',
        send_on_inject: 'Відправити при інжекті?',
        direction: 'Напрямок',
        direction_group: 'Напрямок групи',
        is_answer: 'Відповідь',
        not_answered: 'Не відповіли',
        answered: 'Відповіли',
        method_determining_country: 'Метод визначення країни',
        received_country: 'Отримана країна',
        use_hold_status: 'Використовувати hold статус',
        hold: 'На утриманні',
        at_work: 'В роботі',
        on_pause: 'На паузі',
        delayed_call_status: 'Затриманий call status',
        new_unsigned_lead: 'Новий нерозподілений лід',
    },
}