import { FormattedMessage } from "react-intl";
import React from "react";

export const translateStatus = (status, f) => {
  switch (status) {
    case 'error':
      return f ? f({id: 'sending_error'}) : <FormattedMessage id="sending_error" />;
    case 'pending':
      return  f ? f({id: 'pending'}) : <FormattedMessage id="pending" />;
    case 'notDistributed':
      return  f ? f({id: 'new_call_status'}) : <FormattedMessage id="new_call_status" />;
    case 'unsigned':
      return  f ? f({id: 'undistributed'}) : <FormattedMessage id="undistributed" />;
    case 'rejected':
      return  f ? f({id: 'rejected'}) : <FormattedMessage id="rejected" />;
    case 'approved':
      return  f ? f({id: 'approved'}) : <FormattedMessage id="approved" />;
    case 'hold':
      return  f ? f({id: 'hold'}) : <FormattedMessage id="hold" />;
    case 'all':
      return  f ? f({id: 'all'}) : <FormattedMessage id="all" />;
    case 'test':
      return  f ? f({id: 'test'}) : <FormattedMessage id="test" />;
    case 'live':
      return  f ? f({id: 'live'}) : <FormattedMessage id="live" />;
    case 'inject':
      return  f ? f({id: 'inject'}) : <FormattedMessage id="inject" />;
    case 'resend':
      return  f ? f({id: 'resend'}) : <FormattedMessage id="resend" />;
    case 'inject_file':
      return  f ? f({id: 'inject_file'}) : <FormattedMessage id="inject_file" />;
    case 'inject_form':
      return  f ? f({id: 'inject_form'}) : <FormattedMessage id="inject_form" />;
    case 'inject_box':
      return  f ? f({id: 'inject_box'}) : <FormattedMessage id="inject_box" />;
    default:
      return '-';
  }
};

export const translateType = (status) => {
  switch (status) {
    case 'resend':
      return <FormattedMessage id="resend" />;
    case 'changeDns':
      return <FormattedMessage id="changeDns" />;
    case 'export':
      return <FormattedMessage id="export_column" />;
    case 'import':
      return <FormattedMessage id="import" />;
    case 'importFile':
      return  <FormattedMessage id="import_file" />;
    case 'purchase':
      return <FormattedMessage id="purchase" />;
    case 'inject-bath':
      return <FormattedMessage id="mass_inject" />;
    default:
      return '-';
  }
};

export const translateStatusDetail = (status) => {
  switch (status) {
    case 'success':
      return <FormattedMessage id="successful" />;
    case 'partiallySuccessful':
      return <FormattedMessage id="partially_successful" />;
    case 'error':
      return <FormattedMessage id="error" />;
    case 'deleted':
      return <FormattedMessage id="suspended" />;
    case 'ongoing':
      return  <FormattedMessage id="in_processing" />;
    default:
      return '-';
  }
};

export const hasSystemStatus = (status) => {
  return (
    status === 'error' || status === 'notDistributed' || status === 'unsigned' || status === 'hold'
  );
};

export const getColorByStatus = (status) => {
  switch (status) {
    case 'error':
      return '#cf1322';
    case 'pending':
      return '#ff9900';
    case 'notDistributed':
      return '#1d39c4';
    case 'unsigned':
      return '#531dab';
    case 'rejected':
      return '#d46b08';
    case 'approved':
      return '#389e0d';
    case 'hold':
      return '#09e6e6';
    case 'all':
      return 'rgb(217, 217, 217)';
    case 'test':
      return 'rgb(255,2,250)';
    default:
      return '-';
  }
};
