import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import { Button, Form, Input, Select } from 'antd';
import logoWhite from '../../images/midas_white.png';
import api from '../../api/api';
import {useIntl} from "react-intl";
import {LockOutlined} from "@ant-design/icons";
import { InputOTP } from 'antd-input-otp';
export const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOtpEnabled, setIsOtpEnabled] = useState(false)
  const [isOtpStatus, setIsOtpStatus] = useState('default')
  const [selectType, setSelectType] = useState({
    value: localStorage.getItem('entry_type') || 'buyer',
  });
  const { formatMessage: f } = useIntl();
  const saveDataAndRedirect = async (token) => {
    try {
      await localStorage.setItem('access_token', token);
      await localStorage.setItem('entry_type', selectType.value);
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  }
  const onFinish = (_,token = '') => {
    setIsDisabled(true);
    api
      .post('/auth/login', {
        email: form.getFieldValue('email'),
        password: form.getFieldValue('password'),
        type: selectType.value,
        device: navigator.userAgent,
        token: token,
      })
      .then((res) => {
        if(res.data?.otp) {
          setIsOtpEnabled(true)
        } else if(res.data?.validate === false) {
            setIsOtpStatus('error')
        } else {
          saveDataAndRedirect(res.data.access_token)
        }
      })
      .catch((res) => {
        console.error(res);
        if (res?.response?.status === 401) {
          setIsError(true);
        }
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const optionsSelect = () => {
    return [
      {
        value: 'buyer',
        label: f({id: 'webmaster'}),
      },
      {
        value: 'user',
        label: f({id: 'manager'}),
      },
    ];
  };

  return (
    <div className={styles.body}>
      <div className={styles.formContainer}>
        <div className={styles.logoContainer}>
          <img src={logoWhite} alt="" className={styles.logo} />
        </div>
        { isOtpEnabled ? ( <>
            <LockOutlined style={{ fontSize: '30px'}} className="mb-5" />
            <InputOTP autoFocus={true} disabled={isDisabled} status={isOtpStatus} autoSubmit={(token) => onFinish('',token.join(''))} inputType="numeric" />
            <h3 style={{textAlign: 'center'}}>Enter your two-step verification code</h3>
        </> ) : (
        <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
          <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: f({id: 'email_validate'}),
                },
              ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
              label={f({id: 'password'})}
              name="password"
              rules={[
                {
                  required: true,
                  message: f({id: 'password_validate'}),
                },
              ]}
          >
            <Input.Password />
          </Form.Item>
          {isError && (
              <Form.Item className={styles.errorContainer}>
                <div className={styles.error}>{f({id: 'error_login'})}</div>
              </Form.Item>
          )}
          <Form.Item name="type">
            <Select
                defaultValue={localStorage.getItem('entry_type') || 'buyer'}
                onChange={(e) => {
                  setSelectType({ value: e });
                }}
                style={{
                  width: 120,
                }}
                options={optionsSelect()}
            />
          </Form.Item>
          <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
          >
            <Button
                loading={isDisabled}
                disabled={isDisabled}
                type="primary"
                htmlType="submit"
            >
              {f({id: 'sign_in'})}
            </Button>
          </Form.Item>
        </Form>)}
      </div>
    </div>
  );
};
